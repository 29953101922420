import { API_HOST } from '../../App'

export function makeFormData(values, ...fields) {
    let fd = new FormData()
      , fs = new Set(fields)
      , bad = new Map()

    for (let k in values) {
        if (fs.has(k) || fs.size === 0) {
            let v = values[k]
            if (!v) {
                bad.set(k, v)
            } else {
                fd.append(k, v)
            }
        }
    }
    return bad.size > 0 ? bad : fd
}

export function prepareImageUrl(url) {
    return API_HOST + "/" + url
}
