import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd'

import useResizeWindows from '../../../utils/hooks/useResizeWindow'
import { RestaurantContext } from '../../../providers/restaurant';
import { API_URL } from '../../../App';

import './styles.scss';


const VisitorRulesModal = ({open, setOpen}) => {
  const {
    places, current,
  } = useContext(RestaurantContext)
  const [loading, setLoading] = useState(false)
      , [t, sT] = useState()

  useEffect(() => {
    if (places?.[current]?.id) {
      setLoading(true)
      // ?rest_id=${places?.[current]?.id}
      fetch(API_URL + `/settings/visitor_rules`).then(
        async res => {
          if (res.status < 400) return res.text()
          else return Promise.reject(await res.text())
        }
      ).then(
        content => sT(content)
      ).catch(
        e => console.error(e)
      ).finally(
        () => setLoading(false)
      )
    }
  }, [places, current])

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const width = useResizeWindows()

  return (
    <Modal
        open={open}
        title={<span style={{whiteSpace: 'nowrap'}}>ПРАВИЛА{width < 884 ? <br/> : ' '}ПОСЕЩЕНИЯ</span>}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        // closeIcon={
        //   width < 448 ? <div
        //     className='modal-mobile-closer'
        //   ></div>
        //   : undefined
        // }
        style={{
          maxWidth: '80%',
          minWidth: 360,
        }}
      >{
        loading ?
        "Loading..."
        : <div dangerouslySetInnerHTML={{__html: t}}></div>
      }</Modal>
  )
}

export function RulesModal({
  ...props
}) {
  const [loading, setLoading] = useState(false)
      , [t, sT] = useState()
      , {open, setOpen} = props
  useEffect(() => {
    setLoading(true)
    fetch(API_URL + "/settings/reservation_rules").then(
      async res => {
        if (res.status < 400) return res.text()
        else return Promise.reject(await res.text())
      }
    ).then(
      content => sT(content)
    ).catch(
      e => console.error(e)
    ).finally(
      () => setLoading(false)
    )
  }, [])

  return <Modal
    open={open}
    onCancel={() => setOpen(false)}
    footer={null}
    style={{
      maxWidth: 'min-content',
    }}
  >{
    loading ?
    "Loading..."
    : <div dangerouslySetInnerHTML={{__html: t}}></div>
    }</Modal>
}

export default VisitorRulesModal;
