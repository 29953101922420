import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';

const RequestModal = ({
  open, setOpen, result, setResult,
}) => {
  const handleCancel = () => {
    setOpen(false);
    setResult()
  };

  const errorMessage = useMemo(() => {
    const e = result?.error
        , status = result?.status
    let additionalText
      
    if (typeof e === 'string') {
      if (e.startsWith('<!DOCTYPE html>')) { // god bless HTML 5
        additionalText = <div className='bad' dangerouslySetInnerHTML={{__html: result?.error}}></div>
      } else if (status >= 500) {
        additionalText = <pre>{e}</pre>
      } else if (status >= 400) {
        additionalText = e
      }
    }
    return e ? (
      <>
        {
          status >= 500 ? (
            <div>
              {/* <h3>Наш backend-developer Артёмка только учится программировать (уже 2,5 года)</h3>
              <p>Он плоховато запрограммровал сервер на обработку этого запроса, и он поправит положение как-только узнает об этом</p> */}
              <p>Непредвиденная ошибка при обработке запроса</p>
              {additionalText && <p>Подробности для разработчиков веб-сайта:{
                additionalText
              }</p>}
            </div>
          ) : status >= 400 ? (
            <div className='bad'>
              <p>Зявка на бронирование не может быть оставлена по следующей причине:</p>
              <p>{additionalText}</p>
            </div>
          ) : null
        }
      </>
    ) : null
  }, [result])

  return (
    <Modal
        open={open}
        onCancel={handleCancel}
        footer={null}
        style={{
          maxWidth: 'max-content',
        }}
      >
        {
            result?.success ? (
              <div className='request-modal'>
                <div className='good'>
                  <div className='body'>
                  Ваша заявка принята.<br/>С&nbsp;вами свяжутся в&nbsp;ближайшее время!
                  </div>
                  {
                    !(result.specialMenuDate) && <Link to="/order">
                      <p className="preorder-link">
                        Составить предзаказ?
                      </p>
                    </Link>
                  }
                </div>
              </div>
            )
            : !result?.error ? <p className="reservation-pending tg-authentic tg-upper">Идёт обработка...</p>
            : null
          }
        {
          errorMessage
        }
      </Modal>
  )
}

export default RequestModal;
