import { useEffect, useRef } from 'react'
import LocationSvg from '../assets/icons/location.svg'
import * as Leaflet from 'leaflet/dist/leaflet'
const L = Leaflet


export function useMap({
  x, y, bounds,
}) {
    let map = useRef()

    useEffect(() => {
        let maxBounds
        if (bounds) {
          const [c1, c2] = bounds
              , c1_ = c1?.toReversed?.()
              , c2_ = c2?.toReversed?.()
          if (c1_ && c2_) {
            maxBounds = [
              c1_, c2_
            ]
          }
        }
        if (!map.current && x && y) {
          try {
            map.current = L.map('map', {
              maxBounds,
              maxBoundsViscosity: 0.5,
            })
            L.tileLayer('https://doski-tiles.barskiylab.com/tile/{z}/{x}/{y}.png', {
                minZoom: 11,
                maxZoom: 18,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map.current)
          } catch (e) {
            console.warn(e)
          }
        }
        if (map.current && x && y) {
          if (maxBounds) map.current.setMaxBounds(maxBounds)
          map.current.setView([x, y], 15)
          L.marker(
            [x, y],
            {
                icon: L.icon({
                    iconUrl: LocationSvg,
                    iconSize: [48, 48],
                })
            },
        ).addTo(map.current)
        }
    }, [x, y, bounds])

    return map
}
