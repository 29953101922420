import { createContext } from 'react'


export const RestaurantContext = createContext({
    places: [],
    current: -1, setCurrent(v) {
        this.current = v
        const p = this.places?.[this.current]
        if (!p) {
            this.current = -1
            return
        }
        console.log(p)
    },
})
