import { useNavigate } from "react-router-dom"
import { Modal } from "antd"

import { API_HOST } from '../../../App';

import localStyle from "./style.module.scss"


export const pathConverter = (promotion) => {
    return promotion ?
    API_HOST + '/media/' + promotion.image
    : undefined
}

const nbspIfEmpty = (res, props) => {
    const double = props.double ?
        <><br/>&nbsp;</>
        : null
    return res ? <p {...props}>{res}</p> : props.inModal ? null : <p {...props}>&nbsp;{double}</p>
}

const nameExtractor = (promotion) => {
    return promotion?.name
}
const timeExtractor = promotion => {
    let ret = promotion?.time
    const parts = ret?.split('/')
    if (parts && parts.length > 1) {
        ret = <>
                <span>{parts[0]}&nbsp;/</span>
                <br/>
                <span>{parts[1]}</span>
            </>
    }
    return ret
}

export function EventInfo({
    promotion,
    needButton = false,
    inModal = false,
}) {
    const navigate = useNavigate()

    return (
        <div className={needButton ? localStyle['info'] : undefined} data-event-details={needButton ? undefined : 'info'}>
            <div>
                {
                    nbspIfEmpty(nameExtractor(promotion), {'data-info': 'title', inModal})
                }
                {
                    nbspIfEmpty(timeExtractor(promotion), {'data-info': 'time', double: true, inModal})
                }
                {
                    nbspIfEmpty(promotion?.constraints, {'data-info': 'constraints', inModal})
                }
                {
                    inModal && <pre data-info="decsription">{promotion?.description}</pre>
                }
            </div>
            {
                needButton && <button className='primary'
                    onClick={e => {
                        navigate('/reservation')
                    }}
                >Забронировать стол</button>
            }
        </div>
    )
}

export default function C({
    ...props
}) {
    // const width = useResize()
    const {
        eventDetails,
        setEventDetails,
    } = props

    return (
        <Modal
            open={eventDetails.showing}
            onCancel={e => {
                setEventDetails({showing: false})
            }}
            footer={null}
            // closeIcon={
            //     width < 448 ? <div
            //       className='modal-mobile-closer'
            //     ></div>
            //     : undefined
            //   }
            className={localStyle['event-details'] + ' ' + localStyle['modal-wrapper']}
            data-modal="promotion-details"
            style={{
                top: 32,
            }}
            >
            <div className={localStyle['content']}>
                <img height="576" width="408"
                    src={pathConverter(eventDetails?.promotion)}
                    onClick={() => setEventDetails({showing: false})}
                    alt={nameExtractor(eventDetails?.promotion)}
                />
                <EventInfo promotion={eventDetails.promotion} needButton={true} inModal={true} />
            </div>
        </Modal>
    )
}