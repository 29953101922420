import React from 'react';
import { useNavigate } from 'react-router-dom';
import Tag from '../../shared/Tag/Tag';

import { ReactComponent as FollowLinkSvg } from '../../../assets/icons/followLink.svg';
import { Button } from 'antd';

import './style.scss';


export const Card = ({
  direction, tag, title, text, className, src, id,
  url,
}) => {
  const navigate = useNavigate()
  //tag='Вечером' title='Бар' 
  //text='В нашем баре вас ждет огромное количество напитков'
  //
  return (
    <div className={`card card__${direction}`}>
      <div className='body'>
        {/* <Tag>{tag}</Tag> */}
        <div className='description'>
          <h2 className={className}>{title}</h2>
          <div className='text'>{text}</div>
          <Button className='link more-details'
            onClick={e => {
              navigate(url)
            }}
          ><div className='link-text'>Подробнее</div><FollowLinkSvg/></Button>
          {/* <div className='link'>Подробнее</div> */}
        </div>
      </div>
      <img src={src} alt={`card-${id}`} />
    </div>
  )
}
