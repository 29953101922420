import React, { useState, useEffect } from 'react'

import { Modal, Form, Input, Checkbox, Button, Spin } from 'antd'
import { MaskedInput } from 'antd-mask-input'

import Ticker from '../../components/layout/Ticker/Ticker'
import CardCarousel from './CardCarousel.js'
import Svg from './SvgDoski.js'
import PartnerEvent from './PartnerEvent.js'

import { usePersonalDataModal, PersonalDataModal } from '../../components/shared/PersonalData/index.js'
import { useScrollOnRender } from '../../utils/hooks/scroll'
import useResizeWindows from '../../utils/hooks/useResizeWindow.js'

import { makeFormData } from '../../utils/helpers/request'

import BGImage from '../../assets/img/partner/hero.png'

import { itemRules } from '../../utils/helpers/constants.js'
import { API_HOST } from '../../App'

import "./style.scss"


const eventsImage = [
  { img: '1.jpg'},
  { img: '2.jpg'},
  // { img: '3.jpg'},
  { img: '4.jpg'},
  { img: '5.jpg'},
  { img: '6.jpg'},
  { img: '7.jpg'},
  { img: '8.jpg'},
  { img: '9.jpg'},
  { img: '10.jpg'},
  { img: '11.jpg'},
  { img: '12.jpg'},
]

const benefits = [
      {
        imgNum: '1', iconNum: '1', text: 'Конкурентная ценовая политика',
      },
      {
        imgNum: '2', iconNum: '2', text: 'Высокое качество продукта',
      },
      {
        imgNum: '3', iconNum: '3', text: 'Доступ к уникальным многогодовым контрактам',
      },
      {
        imgNum: '4', iconNum: '4', text: 'Высокий уровень сервиса',
      },
      {
        imgNum: '5', iconNum: '5', text: 'Готовый дизайн-проект',
      },
      {
        imgNum: '6', iconNum: '6', text: 'Готовые технологические карты блюд и напитков'
      },
      {
        imgNum: '7', iconNum: '7', text: 'Система контроля и учёта деятельности'
      },
      {
        imgNum: '8', iconNum: '8', text: 'Организованная работа производства'
      },
      {
        imgNum: '9', iconNum: '9', text: 'Готовый контент и макеты для рекламы'
      },
]

const supportItems = [
    {
      imgNum: "1",
      imgFormat: "jpeg",
      iconNum: "1",
      title: "Персонал",
      list: [
        "Инструменты по поиску персонала",
        "Обучение и программы мотивации",
        "Системы контроля качества работы",
        "Формирование заработной платы",
      ],
    },
    {
      imgNum: "2",
      imgFormat: "jpeg",
      iconNum: "2",
      title: "Оборудование и поставки",
      list: [
        "Поставщики: мебели, продуктов, оборудования и т.д.",
        "Сопровождение контрактов и партнёрские скидки",
      ],
    },
    {
        imgNum: "3",
        imgFormat: "jpeg",
        iconNum: "3",
        title: "Склад и хранение",
        list: [
          "Система складского учёта",
          "Маркировка, сроки годности и количество сырья",
          "Списание продуктов",
        ],
      },
      {
        imgNum: "4",
        imgFormat: "jpeg",
        iconNum: "4",
        title: "Юридические и бух. вопросы",
        list: [
          "Помощь в получении лицензии",
          "Кассовые документы",
          "Выбор системы налогообложения",
        ],
      },
      {
        imgNum: "5",
        imgFormat: "jpeg",
        iconNum: "5",
        title: "Реклама и маркетинг",
        list: [
          "Предоставление базового контента, правил ведения соцсетей",
          "Сопровождение любых видов рекламы",
          "Составление маркетингового плана",
          "Разработка полиграфической продукции и pos- материалов",
          "Помощь в организации арт-мероприятий",
        ],
      },
      {
        imgNum: "6",
        imgFormat: "jpeg",
        iconNum: "6",
        title: "Открытие заведения",
        list: [
          "План открытия",
          "Смета на открытие",
          "Спецификация необходимого оборудования и инвентаря",
          "Финансовая модель",
          "Команда по запуску объекта",
        ],
      },
      {
        imgNum: "7",
        imgFormat: "jpeg",
        iconNum: "7",
        title: "Помещение",
        list: [
          "Рекомендации по поиску помещения - договор аренды",
          "Дизайн-проект",
          "Архитектурный проект и план производственного и непроизводственного оборудования",
        ],
      },
      {
        imgNum: "8",
        imgFormat: "jpeg",
        iconNum: "8",
        title: "Стандарты работы",
        list: [
          "Организационный процесс и чек-листы",
          "Правила эксплуатации и обслуживания",
          "Рецептуры и видеоуроки",
          "Работа с 1С и rKeeper",
        ],
      },
]

export default function Parther() {
    const contSel = "slider-cards"
    const [loadingImages, setLoadingImages] = useState(true);

    const [loadedBenefits, setLoadedBenefits] = useState([]);
    const [loadedSupportItems, setLoadedSupportItems] = useState([]);
    const [loadedEvents, setLoadedEvents] = useState([]);

    useScrollOnRender(true)
    const width = useResizeWindows()

    // если это всё попытка мимикрировать под загрузку с сервера - то она не очень
    // TODO: в конце концов перенести в вёрстку
    useEffect(() => {
      if (loadingImages) {
        // когда я буду делать софт для школы программирования для лаосских/тайских/... детей,
        // я буду использовать такие имена, чтобы было как в американских телешоу
        const MISTER_BENEFITS_PREFIX = `${API_HOST}/static/restaurant/img/partner/benefits`
        setLoadedBenefits(benefits.map(
          benefit => {
            const iconPath = `${MISTER_BENEFITS_PREFIX}/icon-${benefit.iconNum}.svg`
              , img  = <img className="card-picture"
                              src={`${MISTER_BENEFITS_PREFIX}/${benefit.imgNum}.png`}
                              alt={`неоспоримое преимущество № ${benefit.imgNum}`}
                            />
              , icon = <img className="icon"
                              src={iconPath}
                              alt=""
                            />
  
            return { ...benefit, img, icon }
          }
        ))

        const MISTER_SUPPORT_PREFIX = `${API_HOST}/static/restaurant/img/partner/support`
        setLoadedSupportItems(supportItems.map(
          (item, idx) => {
            const isEven = idx % 2 === 0;
            const img = (
              <>
                <img className="mobile-only" src={`${MISTER_SUPPORT_PREFIX}/${item.imgNum}.${item.imgFormat}`} alt="" />
                <img className="mobile-hidden" src={`${MISTER_SUPPORT_PREFIX}/${item.imgNum}.${item.imgFormat}`} alt="" />
              </>
            )
            const icon = <img src={`${MISTER_SUPPORT_PREFIX}/icon-${item.iconNum}.svg`} alt="" />

            return { ...item, img, icon }
          }
        ))

        setLoadedEvents(eventsImage.map(
          item => {
            const img = <img src={`${API_HOST}/static/restaurant/img/partner/events/${item.img}`} alt=""/>
            return { ...item, img}
          }
        ))

        setLoadingImages(false);
      }
    }, [loadingImages]);

    useEffect(() => {
      // Ж) (артёмий язвительнов был здесь)
      document.body.style.background = 'top/contain no-repeat, #131313'
      document.body.style.background = BGImage
      return () => {
        document.body.removeAttribute('style')
      }
    }, [])

    // если текст закомментирован, значит он не очень нужен, верно? :)

    const renderSupportItems = () => {
      return loadedSupportItems.map((item, index) => {
        const isEven = index % 2 === 0;
        return (
          <div key={index} className="card">
            {!isEven && item.img}
            <div className="details">
              {item.icon}
              <div className='content'>
                <p className="tg-authentic large tg-upper">{item.title}</p>
                <ul>
                  {item.list.map((listItem, listIndex) => (
                    <li key={listIndex}>{listItem}</li>
                  ))}
                </ul>
              </div>
            </div>
            {isEven && item.img}
          </div>
        );
      });
    };

    const iI = {open: false}
    const [inquiry, setInquiry] = useState(iI)

    function openInquiry(e) {
      setInquiry(prev => ({...prev, open: true}))
    }
    function errorInquiry(e) {
      setInquiry(prev => ({...prev, error: e, result: undefined}))
    }
    function okInquiry(result) {
      setInquiry(prev => ({...prev, result, error: undefined}))
    }
    function closeInquiry() {
      setInquiry(iI)
    }

    function submitInquiry(values) {
      setInquiry(prev => ({...prev, submitting: true}))
      const body = makeFormData(
        values,
        'name', 'phone',
      )
      if (body instanceof Map) {
        let e = "некорректно заполненная форма:<br>"
        body.forEach(
          (v, k) => {
            e += `${k}: ${v || 'пусто'}<br>`
          }
        )
        errorInquiry(e)
        return
      }
      fetch(API_HOST + '/franchise', {
        method: 'POST',
        // mode: 'cors',
        // credentials: 'omit',
        body,
      }).then(
        response => response.json()
      ).then(
        content => {
          okInquiry(content)
        }
      ).catch(
        e => {
          errorInquiry(`${e}`)
        }
      ).finally(
        () => setInquiry(prev => ({...prev, submitting: false}))
      )
    }

    const submitter = values => {
      openInquiry()
      console.log('franchise submission values', values)
      submitInquiry(values)
    }

    const {
      personalDataModal, open, close,
    } = usePersonalDataModal()

    return (
        <>
        <Modal
          open={inquiry.open}
          title={inquiry.error ? 'Что-то пошло не так...' : inquiry?.result?.status === 'success' ? '' : "Оставьте заявку"}
          onOk={() => {
            if (inquiry.result) {
              closeInquiry()
            }
          }}
          onCancel={() => {
            closeInquiry()
          }}
          // closeIcon={
          //   width < 448 ? <div
          //     className='modal-mobile-closer'
          //   ></div>
          //   : undefined
          // }
          style={width < 448 ? {
            position: 'relative',
            top: '40%',
          } : {
            maxWidth: 768,
          }}
          footer={null}
        >{
          inquiry.submitting ?
          "Ожидаем сервер"
          : inquiry.error || inquiry.result ?
          <div className='request-modal'>
            {
              (inquiry.error || inquiry.result.status === 'error') && (
                <div className='bad' dangerouslySetInnerHTML={{__html: inquiry.error}}></div>
              )
            }
            {
              inquiry?.result?.status === 'success' && (
                <div className='good'>
                  <div className='header'></div>
                  <div className='body'>
                  Ваша заявка принята. Мы с Вами свяжемся в ближайшее время!
                  </div>
                </div>
              )
            }
          </div>
          : (
            <>
            <p>В ближайшее время мы свяжемся с Вами!</p>
            <div className='form-wrapper' data-id="partner-modal">
                <Form
                  onFinish={submitter}
                >
                  <Form.Item label='Ваше имя' name='name'
                    rules={itemRules}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item label='Телефон' name='phone'
                    rules={itemRules.concat([{
                      pattern: /\(\d{3}\)\d{3}-\d{2}-\d{2}/,
                      validateTrigger: 'blur',
                      message: "Телефон заполнен не до конца",
                    }])}
                  >
                    <MaskedInput
                      defaultValue=''
                      mask={'(000)000-00-00'}
                      addonBefore={<Form.Item name="prefix" noStyle>
                        <div>+7</div>
                      </Form.Item>}
                    />
                  </Form.Item>
                  <Form.Item label='Комментарий' name='comment'>
                    <Input/>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox required>
                      С <a href="/agreement"
                        onClick={e => {
                          e.preventDefault()
                          open()
                        }}
                      >условиями обработки персональных данных</a> согласен</Checkbox>
                  </Form.Item>
                  <Button htmlType="submit">
                    Отправить
                  </Button>
                </Form>
            </div>
            </>
          )
        }</Modal>
        <PersonalDataModal
          {...personalDataModal} close={close}
        />
        <section className="hero-partner class-body">
          <h1 className='meta'><p className="tg-upper tg-authentic">Стань партнёром</p></h1>
          <p className="with-brand tg-upper tg-authentic">
            <span>надёжного<br/> бренда</span>
              <Svg/>
          </p>
          <button className="primary tg-upper"
            onClick={openInquiry}
          >оставить заявку</button>
        </section>
        
        <section className="benefits">
            <Ticker name={"advantage"} style={{
              color: 'white',
            }}/>
            <div className={contSel}>
                {/** 
                 * а представьте, если бы человек на этом этапе предложил бы уже
                 * "а давайте ебанём спиннер сразу", и сразу станет его замысел понятен
                 * и артёмий девелоперофф уже сразу брал бы его в свой код везде сразу
                 * 
                 * но нет
                 * */}
                {
                  loadingImages ? <Spin size="large" tip="Формируем список преимуществ работы с нами..."><div className="content" /></Spin>
                  : <CardCarousel loadedBenefits={loadedBenefits} openInquiry={openInquiry} />
                }
            </div> 
        </section>
        {/* <hr/> */}
        <div className="slider-cards-partner">
          <PartnerEvent loadedEvents={loadedEvents} />
        </div>
        {/* <hr/> */}
        <section className="support">
          <aside>
            <p className="tg-authentic huge tg-upper">Наша поддержка</p>
            <button className="primary tg-upper"
              onClick={openInquiry}
            >Оставить заявку</button>
          </aside>
          <div className="cards">{
            loadingImages ? <Spin size="large" tip="Формируем список преимуществ работы с нами..."><div className="content" /></Spin>
            : renderSupportItems()
          }</div>
        </section>
        <section className='supportWindow'>
          <div className='supportFormWrapper'>
            <div className='firstColumn'>
              <p>ОСТАВЬТЕ ЗАЯВКУ</p>
              <div className='text'>В ближайшее время мы свяжемся с Вами!</div>
            </div>
            <div className='supportForm'>
              <Form
                onFinish={submitter}
                // onFinishFailed={}
              >
                <Form.Item label='Ваше имя' name='name'
                  rules={itemRules}
                >
                  <Input />
                </Form.Item>
                <Form.Item label='Телефон' name='phone' data-id="heal-me"
                  rules={itemRules.concat([{
                    pattern: /\(\d{3}\)\d{3}-\d{2}-\d{2}/,
                    validateTrigger: 'blur',
                    message: "Телефон заполнен не до конца",
                  }])}
                >
                  <MaskedInput
                    defaultValue=''
                    mask={'(000)000-00-00'}
                    addonBefore={<Form.Item name="prefix" noStyle>
                      <div>+7</div>
                    </Form.Item>}
                  />
                </Form.Item>
                <Form.Item label='Комментарий' name='description'>
                  <Input/>
                </Form.Item>
                <Form.Item>
                  <Checkbox required>С <a href="/agreement"
                    onClick={e => {
                      e.preventDefault()
                      open()
                    }}
                  >условиями обработки персональных данных</a> согласен</Checkbox>
                </Form.Item>
                <Button htmlType="submit">
                  Отправить
                </Button>
              </Form>
            </div>
          </div>
        </section>
        
        </>
    )
}
