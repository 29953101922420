//autoWidthSize
import { useEffect, useState } from 'react';

const useResizeWindows = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = (e) => setWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return width;
};

export default useResizeWindows;
