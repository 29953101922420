import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { downloadMenu, MenuElem } from '../../components/shared/Menu/Menu'

import { RestaurantContext } from '../../providers/restaurant'

import './style.scss'


const Menu = ({
  preorderCalculator = null
}) => {
  const navigate = useNavigate()
  const {
    places, current
  } = useContext(RestaurantContext)

  return (
    <><section className="block container hero-menu">
      <p className="tg-upper"><h1 className='meta'>Меню</h1></p>
      <button
        className="calculator primary tg-upper"
        onClick={e => navigate("/order")}
      >Калькулятор предзаказа</button>
      <button className="download secondary tg-upper"
        onClick={e => downloadMenu(places?.[current]?.menue_file)}
      >Скачать меню</button>
    </section>
    <MenuElem preorderCalculator={preorderCalculator}/>
    </>
  )
}

export default Menu;
