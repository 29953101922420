import React, { useContext, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { DatePicker, Form, Input, Modal, Select } from 'antd'
import { MaskedInput } from 'antd-mask-input';
import { downloadMenu, MenuElem } from '../../components/shared/Menu/Menu'

import heroImg from "../../assets/img/order-dish.png"

import { PreorderContext } from '../../providers/reservation'
import { RestaurantContext } from '../../providers/restaurant'
import { MenueContext } from '../../providers/menue'

import useResizeWindows from '../../utils/hooks/useResizeWindow'

import { ReactComponent as CalendarSvg } from '../../assets/icons/calendar.svg';

import { API_HOST } from '../../App'

import './style.scss'


const weightAccum = (acc, el) => {
    const components = el.weight.split('/').map(c => {
        const subs = c.split('х')
        if (subs.length > 0) { 
            if (subs.length === 1 && subs[0] === c) // no split occured
                return Number(c)
            else if (subs.length === 2) {
                return Number(subs[0]) * Number(subs[1])
            } else {
                console.warn("длина подкомпонентов > 2, я был к такому не готов...")    
            }
        } else {
            console.warn("длина подкомпонентов == 0")
        }
        return NaN
    }).filter(c => !Number.isNan)
    console.log("components now", components)
    return acc + el.quantity * components.reduce((acc, num) => acc + num, 0)
}

function find(items, id) {
    const idx = items.findIndex(i => i.id === id)
    console.log("found", items[idx])
    return {idx, i: items[idx]}
}

function sharePreorder(state) {
    if (navigator.share) {
        navigator.share({
            title: 'Поделиться ссылкой на предзаказ',
            url: API_HOST + state.url
        }).then(
            () => console.log('Share was successful.')
        ).catch(
            (error) => console.log('Sharing failed', error)
        )
    } else {
        alert('Your browser does not support the Share API');
    }
}

// лежало внутри .menu-grid
export default function Preorder() {
    const {
        id: reservationId,
        preorderState, setPreorderState,
    } = useContext(PreorderContext)
    const {places, current} = useContext(RestaurantContext)
    const [guestInfo, setGuestInfo] = useState({
        rest_id: places?.[current].id,
        name: preorderState?.fio || '',
        date: preorderState?.date || undefined,
        phone: preorderState?.phone || undefined,
    })
    const addressOptions_ = useMemo(() => {
        return places?.map((item) => ({label: `${item?.address} (${item?.city})`, value: item?.id}))
    }, [places])
    const {
        menue
    } = useContext(MenueContext)
    // dispatch не удался
    const dyn = {
        "reservation": reservationId,
    }

    const width = useResizeWindows()

    const preorderSum = useMemo(() => {
        const ret = preorderState.menue.reduce((acc, el) => acc + ((el.quantity || 0) * (el.price || 0)), 0)
        console.log("new preorder sum is", ret)
        return ret
    }, [preorderState])

    const [formationError, setFormationError] = useState()

    function showPreorder(e) {
        setPreorderState({...preorderState, showing: true})
    }
    function closePreorder(e) {
        setPreorderState({...preorderState, showing: false})
    }
    function formPreorder(e) {
        if (!reservationId) {
            console.log(`не задано бронирование, пройдите на <a href="/reservation">соответствующую страницу</a>, затем вернитесь на эту`)
        }
        if (preorderState.menue.length > 0) {
            let failed
            setPreorderState(prev => ({...prev, pending: true}))
            const body = Object.assign(dyn, preorderState)
            const d = guestInfo.date
            const date = new Date(
                Date.UTC(
                    d.year(),
                    d.month(),
                    d.date(),
                )
            )?.toISOString().split('T')[0]
            body.guestInfo = {...guestInfo, date}
            fetch(API_HOST + '/preorder', {
                method: 'POST',
                body: JSON.stringify(body),
            }).then(
                response => {
                    if (response.status >= 400) {
                        failed = true
                        return response.text()
                    }
                    else return response.json()
                }
            ).then(
                content => {
                    if (failed || content.status !== 'success') {
                        return Promise.reject(content)
                    }
                    setPreorderState(prev => ({
                        ...prev,
                        formed: true,
                        id: content.id,
                        url: content.filepdf,
                    }))
                }
            ).catch(
                e => {
                    setFormationError(e)
                }
            ).finally(
                () => {
                    setPreorderState(prev => ({...prev, pending: false}))
                }
            )
        } else {
            setFormationError("ничего так и не было выбрано...")
        }
    }

    const [orderRules, setOrderRules] = useState({
        showing: false,
    })
    function closeOrderRules(e) {
        setOrderRules({showing: false})
    }

    useEffect(() => {
        console.log("having reservation id", reservationId, "on preorder page")
    }, [reservationId])

    const adjustMenuElem = (items, id, type) => {
        // console.log("preorderCb for", id)
        const {idx, i: preorderItem} = find(preorderState.menue, id)
        // FIXME: хз, вроде норм работает
        if (preorderItem) {
            const {i: i_} = find(items, id)
            switch (type) {
            case "inc":
                preorderItem.quantity ? preorderItem.quantity++ : preorderItem.quantity = 1
                i_.quantity ? i_.quantity++ : i_.quantity = 1
                break
            case "dec":
                const v = preorderItem.quantity - 1
                if (v === 0) preorderState.menue.splice(idx, 1)
                else preorderItem.quantity = v
                i_.quantity -1 > 0 ? i_.quantity-- : i_.quantity = undefined
                break
            default:
                throw new Error(type)
            }
            // console.log(`${type}reased quantity for`, id)
        } else {
            const {i} = find(items, id)
            // console.log("found", i, "by", idx)
            preorderState.menue.push({...i, quantity: 1})
            i.quantity = 1
        }
        console.log(items[idx])
        setPreorderState({...preorderState })
    }

    const gramsPerPerson = useMemo(() => {
        let ret, totalGrams
        const kitchenItems = preorderState.menue.filter(
            i => i.category?.parent?.name === "Кухня"
        )
        totalGrams = kitchenItems.reduce(weightAccum, 0)
        ret =  totalGrams / preorderState.guests
        return ret
    }, [preorderState])
    const milisPerPerson = useMemo(() => {
        return preorderState.menue.filter(
            i => i.category?.parent?.name === "Бар"
        ).reduce(weightAccum, 0) / preorderState.guests
    }, [preorderState])
    const totalKitchen = useMemo(() => {
        return preorderState.menue.filter(
            i => i.category?.parent?.name === "Кухня"
        ).reduce(
            (acc, el) => acc + el.quantity * el.price, 0
        )
    }, [preorderState])
    const totalBar = useMemo(() => {
        return preorderState.menue.filter(
            i => i.category?.parent?.name === "Бар"
        ).reduce(
            (acc, el) => acc + el.quantity * el.price, 0
        )
    }, [preorderState])

    const calcElem = useMemo(() => {
        return (
            <div className="order-preview">
                <section className="guests mobile-hidden">
                    <p className="title">Количество человек</p>
                    <span className="order-count">
                        <svg className="minus"
                        viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg"
                        onClick={e => {
                            setPreorderState({
                                ...preorderState,
                                guests: preorderState.guests === 1 ? 1 : preorderState.guests - 1,
                            })
                        }}>
                            <circle cx="33.4994" cy="33.7143" r="32.1071" fill="white" stroke="#41B64D" stroke-width="2.21429"/>
                            <path d="M27.2997 37.6594V34.8694H40.5854V37.6594H27.2997Z" fill="#41B64D"/>
                        </svg>
                        <span className="count">{preorderState.guests}</span>
                        <svg className="plus"
                        viewBox="0 0 68 67" fill="none" xmlns="http://www.w3.org/2000/svg"
                        onClick={e => {
                            setPreorderState({
                                ...preorderState,
                                guests: preorderState.guests + 1,
                            })
                        }}>
                            <circle cx="33.9272" cy="33.7143" r="32.1071" fill="white" stroke="#41B64D" stroke-width="2.21429"/>
                            <path d="M32.024 44.3908V35.6665H23.2997V32.8765H32.024V24.1522H34.814V32.8765H43.5161V35.6665H34.814V44.3908H32.024Z" fill="#41B64D"/>
                        </svg>
                    </span>
                </section>
                <section className="food mobile-hidden">
                    <p className="title">Добавленное количество&nbsp;гр</p>
                    <p className="amount">{gramsPerPerson.toFixed()}гр</p>
                    <p className="below">на&nbsp;человека (еда)</p>
                </section>
                <section className="drinks mobile-hidden">
                    <p className="title">Добавленное количество&nbsp;мл</p>
                    <p className="amount">{milisPerPerson.toFixed()}мл</p>
                    <p className="below">на&nbsp;человека (напитки)</p>
                </section>
                <section className="total">
                    <div className="sum">
                        <p className="title">Итого</p>
                        <p className="price tg-authentic">{preorderSum}₽</p>
                    </div>
                    <button className="primary tg-upper online-solutions"
                        onClick={showPreorder}
                    >{
                        (width >= 1400 || width <= 884) ? "Смотреть весь заказ"
                        : <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 442.04 442.04">
                            <g>
                                <g>
                                    <path d="M221.02,341.304c-49.708,0-103.206-19.44-154.71-56.22C27.808,257.59,4.044,230.351,3.051,229.203
                                        c-4.068-4.697-4.068-11.669,0-16.367c0.993-1.146,24.756-28.387,63.259-55.881c51.505-36.777,105.003-56.219,154.71-56.219
                                        c49.708,0,103.207,19.441,154.71,56.219c38.502,27.494,62.266,54.734,63.259,55.881c4.068,4.697,4.068,11.669,0,16.367
                                        c-0.993,1.146-24.756,28.387-63.259,55.881C324.227,321.863,270.729,341.304,221.02,341.304z M29.638,221.021
                                        c9.61,9.799,27.747,27.03,51.694,44.071c32.83,23.361,83.714,51.212,139.688,51.212s106.859-27.851,139.688-51.212
                                        c23.944-17.038,42.082-34.271,51.694-44.071c-9.609-9.799-27.747-27.03-51.694-44.071
                                        c-32.829-23.362-83.714-51.212-139.688-51.212s-106.858,27.85-139.688,51.212C57.388,193.988,39.25,211.219,29.638,221.021z"/>
                                </g>
                                <g>
                                    <path d="M221.02,298.521c-42.734,0-77.5-34.767-77.5-77.5c0-42.733,34.766-77.5,77.5-77.5c18.794,0,36.924,6.814,51.048,19.188
                                        c5.193,4.549,5.715,12.446,1.166,17.639c-4.549,5.193-12.447,5.714-17.639,1.166c-9.564-8.379-21.844-12.993-34.576-12.993
                                        c-28.949,0-52.5,23.552-52.5,52.5s23.551,52.5,52.5,52.5c28.95,0,52.5-23.552,52.5-52.5c0-6.903,5.597-12.5,12.5-12.5
                                        s12.5,5.597,12.5,12.5C298.521,263.754,263.754,298.521,221.02,298.521z"/>
                                </g>
                                <g>
                                    <path d="M221.02,246.021c-13.785,0-25-11.215-25-25s11.215-25,25-25c13.786,0,25,11.215,25,25S234.806,246.021,221.02,246.021z"/>
                                </g>
                            </g>
                        </svg>
                    }</button>
                </section>
            </div>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preorderState, preorderSum, gramsPerPerson, milisPerPerson, width])

    return (
        <>
        <Modal // модалий для содеЖРИмого предзаказа
            open={preorderState.showing}
            // title="Содержание предзаказа"
            onCancel={() => {
                closePreorder()
                setFormationError()
            }}
            footer={formationError ? (
                <p>Ошибка при формировании заказа: <span dangerouslySetInnerHTML={{__html: formationError}}></span></p>
            ) : null}
        >
        <div className='modal' id="order-contents">
            <h3 class="tg-authentic tg-upper">Содержание предзаказа</h3>
            <ul class="items">
                {
                    preorderState.menue && (
                        preorderState.menue.length > 0 ?
                        preorderState.menue.map(
                            i => {
                                return (
                                    <li>
                                        <img src={API_HOST + i.image} alt="" />
                                        <div class="cont">
                                            <span class="name">{i.title}</span>
                                            <div class="aligner">
                                                <span class="order-count">
                                                    {
                                                        !preorderState.formed && (
                                                            <svg class="minus" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                                onClick={e => {
                                                                    adjustMenuElem(menue, i.id, "dec")
                                                                }}
                                                            >
                                                                <circle cx="33.4994" cy="33.7143" r="32.1071" fill="white" stroke="#41B64D" stroke-width="2.21429"/>
                                                                <path d="M27.2997 37.6594V34.8694H40.5854V37.6594H27.2997Z" fill="#41B64D"/>
                                                            </svg>
                                                        )
                                                    }
                                                    <span class="count">{i.quantity}</span>
                                                    {
                                                        !preorderState.formed && (
                                                            <svg class="plus" viewBox="0 0 68 67" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                                onClick={e => {
                                                                    adjustMenuElem(menue, i.id, "inc")
                                                                }}
                                                            >
                                                                <circle cx="33.9272" cy="33.7143" r="32.1071" fill="white" stroke="#41B64D" stroke-width="2.21429"/>
                                                                <path d="M32.024 44.3908V35.6665H23.2997V32.8765H32.024V24.1522H34.814V32.8765H43.5161V35.6665H34.814V44.3908H32.024Z" fill="#41B64D"/>
                                                            </svg>
                                                        )
                                                    }
                                                </span>
                                                <span class="price tg-authentic">{i.quantity * i.price}₽</span>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        )
                        :
                        <>Ничего нет...</>
                    )
                }
            </ul>
            <hr class="mobile-hidden"/>
            <section class="totals">
                <p class="kitchen">
                    <span>Итого кухня:</span>
                    <span class="price tg-authentic">{totalKitchen}₽</span>
                </p>
                <p class="bar">
                    <span>Итого бар:</span>
                    <span class="price tg-authentic">{totalBar}₽</span>
                </p>
                <p class="total">
                    <span>Итого:</span>
                    <span class="price tg-authentic">{preorderSum}₽</span>
                </p>
            </section>
            <hr class="mobile-hidden"/>
            <section className='guest-info'>
                <Form>
                    <div className='row'>
                        <Form.Item required
                            label="Ресторан"
                            style={{
                                width: '100%',
                            }}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={addressOptions_}
                                value={guestInfo.rest_id}
                                onChange={(value, option) => {
                                    setGuestInfo(prev => ({...prev, rest_id: value}))
                                }}
                                // dropdownStyle={{
                                //     width: width > 768 ? '25%' : '90%',
                                // }}
                            />
                        </Form.Item>
                        <Form.Item required
                            label="ФИО"
                        >
                            <Input value={guestInfo.name} onChange={(e) => {
                                setGuestInfo(prev => ({...prev, name: e.target.value}))
                            }} />
                        </Form.Item>
                    </div>
                    <div className='row'>
                        <Form.Item required
                            label="Дата"
                        >
                            <DatePicker
                                value={guestInfo.date}
                                onChange={(date, dateString) => {
                                    setGuestInfo(prev => ({...prev, date}))
                                }}
                                disabledDate={current => {
                                    return current && current < dayjs().subtract(1, 'day')
                                }}
                                suffixIcon={<CalendarSvg style={{
                                    width: '2em', height: 'auto',
                                }} />}
                            />
                        </Form.Item>
                        <Form.Item required
                            label="Телефон"
                        >
                            <MaskedInput
                                value={guestInfo.phone}
                                onChange={(e) => {
                                    setGuestInfo(prev => ({...prev, phone: e.target.value}))
                                }}
                                mask={'(000)000-00-00'}
                                addonBefore={<div className='phone-prefix'>+7</div>}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </section>
            {
                preorderState.formed && <div className='explain'>
                    <p>
                        Ваш предзаказ был сформирован и отправлен в заведение.
                        Мы свяжемся в Вами в рабочее время для его подтверждения и оплаты.
                        Также Вы можете скачать его для Вашего удобства.
                    </p>
                </div>
            }
            <section class="buttons">
                {
                    preorderState.formed ?
                    <>
                    <button className="primary tg-upper" onClick={e => {
                        window.open(API_HOST + preorderState.url, "_blank").focus()
                    }}>Скачать</button>
                    <button className="primary tg-upper" onClick={e => {
                        setPreorderState({
                            guests: 1,
                            comment: "about",
                            menue: [],
                            showing: false,
                            formed: false,
                        })
                    }} style={width > 1440 ? {whiteSpace: 'nowrap'} : {}}>Начать заново</button>
                    <div class="share" onClick={e => sharePreorder(preorderState)}>
                        <span>Поделиться:</span>
                        <div className='icons-cont'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
                                <rect width="40" height="40" rx="20" fill="#41B64D"/>
                                <path d="M27.9268 12.0625C25.9512 10.0937 23.3171 9 20.5366 9C14.7561 9 10.0732 13.6667 10.0732 19.4271C10.0732 21.25 10.5854 23.0729 11.4634 24.6042L10 30L15.561 28.5417C17.0976 29.3438 18.7805 29.7812 20.5366 29.7812C26.3171 29.7812 31 25.1146 31 19.3542C30.9268 16.6563 29.9024 14.0312 27.9268 12.0625ZM25.5854 23.1458C25.3659 23.7292 24.3415 24.3125 23.8293 24.3854C23.3902 24.4583 22.8049 24.4583 22.2195 24.3125C21.8537 24.1667 21.3415 24.0208 20.7561 23.7292C18.122 22.6354 16.439 20.0104 16.2927 19.7917C16.1463 19.6458 15.1951 18.4062 15.1951 17.0937C15.1951 15.7812 15.8537 15.1979 16.0732 14.9062C16.2927 14.6146 16.5854 14.6146 16.8049 14.6146C16.9512 14.6146 17.1707 14.6146 17.3171 14.6146C17.4634 14.6146 17.6829 14.5417 17.9024 15.0521C18.122 15.5625 18.6341 16.875 18.7073 16.9479C18.7805 17.0938 18.7805 17.2396 18.7073 17.3854C18.6341 17.5312 18.561 17.6771 18.4146 17.8229C18.2683 17.9687 18.1219 18.1875 18.0488 18.2604C17.9024 18.4062 17.7561 18.5521 17.9024 18.7708C18.0488 19.0625 18.561 19.8646 19.3659 20.5937C20.3902 21.4687 21.1951 21.7604 21.4878 21.9063C21.7805 22.0521 21.9268 21.9792 22.0732 21.8333C22.2195 21.6875 22.7317 21.1042 22.878 20.8125C23.0244 20.5208 23.2439 20.5938 23.4634 20.6667C23.6829 20.7396 25 21.3958 25.2195 21.5417C25.5122 21.6875 25.6585 21.7604 25.7317 21.8333C25.8049 22.0521 25.8049 22.5625 25.5854 23.1458Z" fill="white"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
                                <rect width="40" height="40" rx="20" fill="#41B64D"/>
                                <path d="M28 12.6022L24.9946 28.2923C24.9946 28.2923 24.5741 29.3801 23.4189 28.8584L16.4846 23.3526L16.4524 23.3364C17.3891 22.4654 24.6524 15.7027 24.9698 15.3961C25.4613 14.9214 25.1562 14.6387 24.5856 14.9974L13.8568 22.053L9.71764 20.6108C9.71764 20.6108 9.06626 20.3708 9.00359 19.8491C8.9401 19.3265 9.73908 19.0439 9.73908 19.0439L26.6131 12.1889C26.6131 12.1889 28 11.5579 28 12.6022Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                    </>
                    : <button className="primary tg-upper" onClick={formPreorder} disabled={preorderState.pending}>{
                        preorderState.pending ? "формируем..."
                        : "сформировать"
                    }</button>
                }
            </section>
            <p class="footnote">*для оплаты предзаказа свяжитесь с администратором бара</p>
        </div>
        </Modal>
        <Modal // правила
            open={orderRules.showing}
            onCancel={closeOrderRules}
            footer={null}
        >
            <div id="order-rules" className="modal mobile-bar">
            <h3 class="tg-authentic tg-upper">Правила предзаказа</h3>
            <ul>
                <li>
                    <span class="num tg-authentic">1</span>
                    <span class="desc">Услуга «Бронирование» - бесплатная. Осуществляются на любое время в промежутке с 12:00 ч. до 22:00 ч. за 10 дней до желаемой даты, при наличии свободных столов.</span>
                </li>
                <hr/>
                <li>
                    <span class="num tg-authentic">2</span>
                    <span class="desc">Услуга «Раннее бронирование» - 500 р. за один стол, осуществляется за 11 и более дней до желаемой даты бронирования.</span>
                </li>
                <hr/>
                <li>
                    <span class="num tg-authentic">3</span>
                    <span class="desc">Заявки на бронь стола обрабатываются на следующий день в период до 14.00 по местному времени.</span>
                </li>
                <hr/>
                <li>
                    <span class="num tg-authentic">4</span>
                    <span class="desc">По истечении 20 минут от времени бронирования, если Гость не предупредил о возможной задержке, администратор вправе разместить другого Гостя за данный стол.</span>
                </li>
            </ul>
            </div>
        </Modal>
        <section className="hero-preorder">
            <h1 className='meta'><p className="tg-authentic tg-upper mobile-hidden">Калькулятор предзаказа</p></h1>
            <p className="tg-authentic tg-upper mobile-only">Предзаказ</p>
            <div className="buttons">
                <button className="secondary tg-upper"
                    onClick={e => downloadMenu(places?.[current]?.menue_file)}
                >Скачать меню</button>
                <button className="text" onClick={e => {
                    setOrderRules({showing: true})
                }}>Правила предзаказа</button>
            </div>
            <img src={heroImg} alt=""/>
        </section>
        <MenuElem view="preorder" preorderCalculator={calcElem} preorderCb={adjustMenuElem}/>
        </>
    )
}
