import { useEffect } from "react"


export function useScrollOnRender(allow) {
    useEffect(() => {
        window.scrollTo({
            top: allow ? 0 : window.scrollY,
            left: allow ? 0 : window.scrollX,
            behavior: "smooth",
        });
    }, [allow])
}
