import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import Locations from '../Locations/Locations';

import { RestaurantContext } from '../../../providers/restaurant'

import useResizeWindows from '../../../utils/hooks/useResizeWindow';
import { useMap } from '../../../hooks/useMap';

import { ReactComponent as YandexFoodSvg } from '../../../assets/icons/yandexFood.svg';
import { ReactComponent as ArrowDownSvg } from '../../../assets/icons/arrowDown.svg';

import localStyle from './styles.module.scss'
import './leaflet-map.scss'
import 'leaflet/dist/leaflet.css'


const OurBars = () => {
  const navigate = useNavigate()
  const width = useResizeWindows();

  const {
    places, current
  } = useContext(RestaurantContext)
    , cp = useMemo(() => places?.[current], [places, current])
    , bounds = useMemo(() => cp?.map_bounds || null, [cp])
    , [x, y] = useMemo(() => cp?.coordinates?.split(',').map(
      coord => {
        return Number(coord.trim())
      }
    ) || [0, 0], [cp])

  useMap({x, y, bounds})

  return (
    cp ? (
      <div className={localStyle?.['our-bars']} id="places">
        <div className={localStyle?.['container']}>
          { width > 770 ? <div className={localStyle?.['wrapperColumns'] + ' ' + localStyle?.['map']}>
            <div className={localStyle?.['current-city-info'] + ' ' + localStyle?.['firstColumn']}>
              <div className={localStyle?.['location-wrapper'] + ' ' + localStyle?.['location-wrapper-map']}>
                <div className={localStyle?.['full-address']}>
                  <div className={localStyle?.['city']}>{cp.city}</div>
                  <div className={localStyle?.['address']}>{cp.address}</div>
                  <div className={localStyle?.['phone']}>{cp.phone}</div>
                </div>
                <div className={localStyle?.['arrow-down-svg']}><ArrowDownSvg/></div>
              </div>

              <div className={(localStyle?.['button'] || '') + ' ' + localStyle?.['greenButton']}>
                <Button onClick={e => {
                  window.scrollTo({
                    top: 0, left: 0, behavior: 'smooth',
                  })
                  navigate('/reservation')
                }} style={{width: '100%',}}><span>забронировать</span><span>стол</span></Button>
              </div>
              <div className={localStyle?.['whiteButton']}>
              <a
                href={`https://3.redirect.appmetrica.yandex.com/route?end-lat=${x}&end-lon=${y}&tariffClass=econom&ref=http%3A%2F%2F5.75.132.167%3A3000&appmetrica_tracking_id=1178268795219780156&lang=ru`}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                }}
              ><Button style={{width: '100%',}}>Заказать такси</Button></a>
              </div>
            </div>
            <div className={localStyle?.['secondColumn']}>
              <div id="map"></div>
            </div>
          </div> : <></>}

          <div className={localStyle?.['block'] + ' ' + localStyle?.['wrapperColumns'] + ' ' + localStyle?.['block-with-locations']}>
            <div className={localStyle?.['firstColumn']}>
              <div className={localStyle?.['titleSection']}>Наши бары</div>
              <div className={localStyle?.['franchisee-big-button'] + ' ' + localStyle?.['greenButton']}>
                <Button onClick={e => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                  navigate('/menu')
                }}>Меню</Button>
              </div>
            </div>
            <div className={localStyle?.['secondColumn'] + ' ' + localStyle?.['main-locations']}>

              <Locations />

              {
                width < 768 && (
                  <div className={localStyle?.['whiteButton']}>
                    <Button style={{width: '100%'}} onClick={e => {
                      window.open(
                        `https://3.redirect.appmetrica.yandex.com/route?end-lat=${x}&end-lon=${y}&tariffClass=econom&ref=http%3A%2F%2F5.75.132.167%3A3000&appmetrica_tracking_id=1178268795219780156&lang=ru`,
                        '_blank'
                      ).focus()
                    }}>Заказать такси</Button>
                  </div>
                )
              }
              <div className={localStyle?.['main-locations-buttons']}>
                <div className={localStyle?.['franchisee-small-button'] + ' ' + localStyle?.['greenButton']}>
                  <Button onClick={e => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    })
                    navigate('/menu')
                  }}>Меню</Button>
                </div>
                {
                  cp.yandex_eda &&
                  <div className={localStyle?.['yandex-food-button']}>
                    <Button
                      onClick={e => {
                        window.open(cp.yandex_eda, '_blank').focus()
                      }}
                    ><div className={localStyle?.['yandex-img-wrapper']}><YandexFoodSvg /></div></Button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : "Loading..."
  )
}

export default OurBars
