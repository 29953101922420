import { useContext, useEffect, useState } from "react"
import { Modal } from "antd"

import { API_HOST } from "../../App"

import gridStyle from './grid.module.scss'
import { RestaurantContext } from "../../providers/restaurant"


function eventMapper(i) {
  return {
    id: i?.id,
    src: API_HOST + '/media/' + i?.image,
    alt: i?.description,
    restarauntId: i.restaraunt_id,
  }
}
export function eventFilterFactory(currentPlaceId) {
  return e => e.restarauntId === null || e.restarauntId === currentPlaceId
}

export function gridImagesMapper(items) {
  return items?.map(eventMapper)
}

function use0Padding(toggle) {
  useEffect(() => {
    const sel = gridStyle['event-details-trigger']
    if (toggle) {
      document.body.classList.add(sel)
    } else {
      document.body.classList.remove(sel)
    }
  }, [toggle])
}

export function EventDetailsModal({
  event, open, onCancel,
}) {
  use0Padding(open)

  return <Modal
    open={open}
    onCancel={onCancel}
    footer={null}
    closeIcon={null}
    data-modal="event-details"
    className={gridStyle["event-details"]}
  ><img
    src={event?.src} alt={event?.alt}
    onClick={onCancel}
  /></Modal>
}

export default function EventsGrid({ items }) {
    const [modalState, setModalState] = useState({
      open: false,
      onCancel: () => setModalState({
        ...modalState,
        open: false,
      }),
    })
        , {
          places, current,
        } = useContext(RestaurantContext)

    // можно писать книгу "садомазохизм, или практика переопределения стилей в дизайн системах"
    use0Padding()

    return (
      <>
      <div className={'block container ' + gridStyle["block-with-grid-images"]}>
        <div className={gridStyle["gridParent"]}>
          {
            items?.filter(
              eventFilterFactory(places?.[current]?.id)
            ).map((item, index) => {
              return <div className={gridStyle["gridChild"] + ' ' + gridStyle[`${"gridChild-" + index}`] + ' ' + gridStyle["grid-image-wrapper"]}
              onClick={e => {
                setModalState({...modalState, open: true, event: item})
              }}
              >
                <img src={item.src} alt={item.alt}/>
              </div>
            }) ?? "Not yet..."
          }
        </div>
      </div>
      <EventDetailsModal
        {...modalState}
      />
      </>
    )
}
