import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import useResizeWindows from "../../utils/hooks/useResizeWindow";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import "./style.scss";
import "./cardCarousel.scss";
import "./partnerEvent.scss";


const PartnerEvent = ({loadedEvents}) => {
  const width = useResizeWindows();
  
  useEffect(() => {
    let space = getSpaceBetween(width);
    let slidersAmount = getSlidesPerView(width);
    console.log('width: ', width);
    console.log('space: ', space);
    console.log('slidersAmount: ', slidersAmount)
  }, [width]);

  const getSpaceBetween = (windowWidth) => {
    if (windowWidth > 2500) return 18;
    if (2259 <= windowWidth && windowWidth <= 2500) return 15;
    if (1439 < windowWidth && windowWidth <= 2259) return 10;
    if (1025 < windowWidth && windowWidth <= 1439) return 10;
    if (769 < windowWidth && windowWidth <= 1025) return 10;
    if (449 < windowWidth && windowWidth <= 767) return 10;
    if (windowWidth > 374 && windowWidth <= 449) return 5;
    if (windowWidth <= 374) return 5;
  };
  const getSlidesPerView = (windowWidth) => {
    //1024
    if (2259 < windowWidth) return 1.72;
    if (1441 < windowWidth && windowWidth <= 2259) return 1.51;
    if (1025 < windowWidth && windowWidth <= 1441) return 1.51;
    if (884 < windowWidth && windowWidth <= 1025) return 1.15;
    if (768 < windowWidth && windowWidth <= 884) return 1.33;
    if (449 < windowWidth && windowWidth <= 768) return 1.33;
    if (windowWidth > 375 && windowWidth <= 449) return 1.33;
    if (windowWidth <= 375) return 1.23;
  };

  const renderCards = () => {
    return (
      <>
        {loadedEvents.map((event, index) => (
          <SwiperSlide>
            <div className="custom-slide">
              <div
                key={index}
                className={`card`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flexGrow: "1",
                    overflow: "hidden",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                >{event.img}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="custom-swiper-4" style={{
        maxWidth: '90vw',
        marginInline: 'auto',
      }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={getSpaceBetween(width)}
          slidesPerView={getSlidesPerView(width)}
          navigation={
            width >= 1024
              ? {
                  nextEl: ".swiper-button-next4",
                  prevEl: ".swiper-button-prev4",
                }
              : false
          }
          pagination={
            width < 1024
              ? { clickable: true, el: ".swiper-pagination4", type: "bullets" }
              : false
          }
          onSlideChange={(swiper) => {
            console.log(swiper);    
          }}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {loadedEvents &&
            loadedEvents.length &&
            loadedEvents.length > 0 &&
            renderCards()}
        </Swiper>
        <div className="swiper-button-prev4"></div>
        <div className="swiper-button-next4"></div>
        <div className="swiper-pagination4"></div>
      </div>    
    </>
  );
};

export default PartnerEvent;
