import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ConfigProvider } from 'antd';
import './index.scss';
// import "./index.css";

import dayjs from "dayjs";
import "dayjs/locale/ru";
import updateLocale from "dayjs/plugin/updateLocale";
import locale from "antd/es/locale/ru_RU";


dayjs.extend(updateLocale);
dayjs.updateLocale("ru", {
  weekStart: 1
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider locale={locale}>
        <App />
      </ConfigProvider>

    </BrowserRouter>
  </React.StrictMode>
);
