import { useContext, useEffect, useMemo, useState } from 'react';

import { Button } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import {
  EventDetailsModal,
  pathConverter,
  EventInfo,
} from '../../shared/EventModal'

import useResizeWindows from '../../../utils/hooks/useResizeWindow';

import { API_HOST } from '../../../App';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import eventStyle from './events.module.scss'
import './styles.scss';
import { RestaurantContext } from '../../../providers/restaurant';

const CustomSwiper = ({
  showDetails = false,
}) => {
  const width = useResizeWindows()
      , {
        places, current,
      } = useContext(RestaurantContext)

  const getSpaceBetween = useMemo(
    () => {
      const aaa = () => {
        if (width > 2500) return 30;
        if (1991 <= width && width <= 2500) return 20;
        if (1439 < width && width <= 1999) return 10;
        if (1023 < width && width <= 1439) return 10;
        if (769 < width && width <= 1023) return 16;
        if (width <= 768) return 16;
      }
  
      let res = aaa();
      return res;
    }, [width]
  )

  const getSlidesPerView = useMemo(() => {
    let ret = 4
    if (width <= 1024) ret = 3;
    if (width <= 768) ret = 2.5;
    if (width <= 448) ret = 1.5;
    if (width <= 376) ret = 1;
    return ret
  }, [width])

  const [promotions, setPromotions] = useState()

  const [error, setError] = useState()

  const [eventDetails, setEventDetails] = useState({
    showing: false,
  })

  useEffect(() => {
    fetch(API_HOST + '/api/promotions').then(
      response => response.json()
    ).then(
      content => setPromotions(content)
    ).catch(
      error => {
        setError(error)
        setPromotions(null)
      }
    )
  }, [])

  return(
    <ErrorBoundary fallback={(
      <>
        <h2>Ой-ой, что пошло не так при загрузке афиши мероприятий...</h2>
        <p>Обычно перезагрузка страницы решает проблему</p>
        <Button onClick={() => {window.location.reload()}}>Перезагрузить страницу</Button>
      </>
    )}>
      <div className='custom-swiper-1'>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={getSpaceBetween}
          slidesPerView={getSlidesPerView}

          navigation={width >= 1024 ? { nextEl: ".swiper-button-next2", prevEl: ".swiper-button-prev2" } : false}

          pagination={width < 1024 ? { clickable: true, el: '.swiper-pagination2', type: 'bullets', } : false}

          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {error ? <>Error during fetch: {error.toString()}</>
          : promotions && promotions.length && promotions.length > 0 && promotions.filter(
            p => p.restaraunt_id === null || p.restaraunt_id === places?.[current]?.id
          ).map(
            p => {
              const path = pathConverter(p)
              return path ? (
                <SwiperSlide
                  onClick={e => {
                    setEventDetails({showing: true, promotion: p})
                  }}
                >
                  <div className={'custom-slide ' + eventStyle['event-details-wrapper']}>
                  <picture>
                    <source srcset={path} type="image/jpeg" media='(min-width: 1430px)'/>
                    <source srcset={path} type="image/jpeg" media='(min-width: 1014px)'/>
                    <source srcset={path} type="image/jpeg" media='(max-width: 770px)'/>
                    <img src={path} alt={p.name || "Unnamed event"} />
                  </picture>
                  {
                    showDetails && <EventInfo promotion={p} />
                  }
                  </div>
                </SwiperSlide>
              ) : null
            }
          )}         
        </Swiper>
        <div className='swiper-button-prev2'></div>
        <div className='swiper-button-next2'></div>

        <div className='swiper-pagination2'></div>
      </div>
      <EventDetailsModal
        eventDetails={eventDetails}
        setEventDetails={setEventDetails}
      />
    </ErrorBoundary>
  )
}

export default CustomSwiper;
