import React from 'react';

import OurBars from '../OurBars/OurBars';
import { HeaderInner } from '../Header/Header'
import { ReactComponent as LinzaSvg } from '../../../assets/icons/footerLinza.svg';

import localStyle from './styles.module.scss'


const Footer = () => {
  return (
    <>
    <OurBars/>
    <div className={localStyle['footer']}>
      <div className={localStyle['footerInner']}>
        <HeaderInner type='footer'/>
      </div>
      <div className={localStyle['secondRow']}>
        {/* <div className={localStyle['footer-logo']}><LogoSvg /></div> */}
        <div className={localStyle['years']}>bardoski © ALL RIGHTS RESERVED. 1998-{new Date().getFullYear()}</div>
        <div className={localStyle['linza-svg']}>
          <LinzaSvg />
        </div>
      </div>
    </div>
    </>
  )
}

export default Footer;