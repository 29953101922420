import { useState } from 'react'
import ChevronLink from '../../../assets/icons/chevron.svg'
import './style.scss'

export default function DD({
    children
}) {
    const [outer, dd] = children
    const [active, setActive] = useState(false)

    return <div
        className={'gutsh-dd' + (active ? ' active' : '')}
        onClickCapture={e => setActive(prev => !prev)}
    >
        {outer}
        <img src={ChevronLink} className='chevron' alt='chevron' />
        <ul className='dd' onClick={e => setActive(prev => false)}>{dd}</ul>
    </div>
}
