import React from "react"
import { useNavigate } from "react-router-dom"

import CustomSwiper from "../../components/layout/CustomSwiper/CustomSwiper";
import AboutCards from "../../components/layout/About";
import Banner from "../../components/Banner/Banner";

import karaokeBackgroundImage from "../../assets/img/bar/karaoke.jpeg";

import "./style.scss";

export default function Karaoke() {
  const navigate = useNavigate()
  return (
    <>
      <Banner
        mainTitle="Караоке"
        buttons={[["Забронировать стол", "/reservation"]]}
        firstParagraph="Бар-ресторан «DOSKI» дает вам возможность проявить свой талант в караоке."
        secondParagraph="Наш просторный и уютный зал караоке оборудован высококачественным звуковым оборудованием и широким выбором песен. У нас вы можете принять участие в караоке-соревнованиях с друзьями, отпраздновать значимое для вас событие или просто насладиться отдыхом в кругу близких людей. Наш опытный персонал всегда настроен на создание приятной атмосферы и поможет вам с выбором необходимой композиции. Караоке — это замечательный способ провести время весело."
        heroClassName="hero-karaoke"
        backgroundImage={karaokeBackgroundImage}
      />
      <section data-section="events">
        <CustomSwiper showDetails={true} />
        <button className="primary tg-upper w-100 mobile-hidden"
          onClick={(e) => {
            navigate("/reservation");
          }}
        >
          Забронировать стол
        </button>
      </section>
      <AboutCards exclude={3} />
    </>
  );
}
