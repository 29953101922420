export default function SvgDoski() {
  return (
    <svg viewBox="0 0 314 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.0379711 20.9754L0 80.7142L22.726 81.0325L22.896 23.0899C32.7677 22.3779 40.8902 30.9575 40.7112 41.4353C40.4343 57.5967 29.1797 59.3577 25.0852 60.6619L25.1112 80.9792C42.8746 80.5002 50.6899 72.3991 57.1464 62.9996C64.5626 52.2034 66.1714 36.7772 59.478 23.9427C53.0918 11.6972 37.7338 0.379925 21.0707 2.49344C16.8465 6.16805 11.2212 11.1296 6.59048 15.2262C3.90734 17.5396 2.49185 18.8168 0.0380897 20.9754H0.0379711Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.8726 1.81251C47.7751 10.3993 57.7021 92.4719 112.304 81.2654C157.274 72.0361 150.021 -7.85457 95.8726 1.81251ZM110.079 59.9695C126.569 51.6594 117.464 13.2441 96.2003 23.0343C95.3706 23.5171 90.5343 25.6582 88.7678 33.7189C87.7785 38.1887 87.8906 44.8075 88.8677 49.188C90.9069 58.3259 100.007 65.0453 110.079 59.9695Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M96.2166 23.0354C117.48 13.2452 126.585 51.6605 110.095 59.9706C100.023 65.0463 90.9226 58.3271 88.884 49.1889C87.907 44.8084 87.7948 38.1894 88.7839 33.7199C90.5504 25.6591 95.3867 23.5183 96.2166 23.0352V23.0354ZM119.714 51.6026C129.749 22.5047 94.3828 7.49811 87.2147 30.7716C78.0421 64.1699 111.604 74.8193 119.714 51.6031V51.6026Z"
        fill="white"
      />
      <path
        d="M196.952 22.9265H172.192C170.209 22.9265 168.982 24.0803 168.451 25.5453C168.019 26.7782 168.015 28.1202 168.441 29.3554C168.441 30.5492 170.352 31.7877 171.575 31.7784C173.621 31.7784 175.824 31.8523 177.867 32.0293C178.966 32.1228 180.059 32.2775 181.141 32.4927C182.392 32.7298 189.879 34.8802 194.948 41.4465C197.717 45.0327 199.779 49.926 199.746 56.522C199.711 63.9563 197.046 69.4209 193.457 73.3074C188.025 79.189 180.432 81.4334 176.663 81.4334H145.541L145.514 71.1402C145.538 67.7084 145.548 64.2868 145.5 60.8546H173.77C175.169 60.9196 176.115 60.0531 176.739 58.876C177.041 58.3061 177.24 57.6873 177.326 57.0484C177.414 56.4267 177.371 55.7935 177.201 55.189C176.816 53.8814 175.692 52.7556 173.518 52.3894C171.988 52.2317 170.548 52.1175 169.081 52.0022C167.859 51.9054 166.616 51.807 164.997 51.6493C160.542 51.0583 155.164 47.6729 152.108 44.5087C149.153 41.4498 146.824 37.4041 146.307 32.3371C145.886 29.7597 146.039 24.8319 146.613 22.2086C147.164 19.6859 148.127 17.7775 149.442 15.4516C150.864 12.927 153.962 9.38151 157.726 6.67892C160.641 4.58691 163.975 2.98079 167.285 2.69866C167.285 1.87971 212.976 2.62184 218.754 2.75445L196.952 22.9265H196.952Z"
        fill="white"
      />
      <path
        d="M212.789 20.5801L233.46 1.94387L235.118 0.44873V32.6054L268.119 3.25639H298.817L260.456 40.3899L275.799 54.8226V83.8743L243.477 55.0133L235.117 62.8326V81.3868H212.789V20.5801Z"
        fill="white"
      />
      <path
        d="M290.938 81.2876V20.1315L313.265 0V81.2876H290.938Z"
        fill="white"
      />
    </svg>
  );
}
