import React from "react"
import { useNavigate } from "react-router-dom"

import { MenuElem } from "../../components/shared/Menu/Menu";
import CustomSwiper from "../../components/layout/CustomSwiper/CustomSwiper";
import AboutCards from "../../components/layout/About";
import Banner from "../../components/Banner/Banner";

import lunchBackgroundImage from "../../assets/img/bar/lunch.jpg";

import "./style.scss"


export default function Dances() {
  const navigate = useNavigate()
  return (
    <>
      <Banner
        mainTitle="Кухня"
        buttons={[["Забронировать стол", "/reservation"]]}
        firstParagraph="Разнообразный выбор блюд по доступным ценам"
        secondParagraph="Меню кухни представляет собой широкий выбор блюд европейской, азиатской и русской классики, которые быстро готовятся и подаются к вам на стол. Большие порции по очень доступным ценам. Встреча с друзьями, день рождения, знаковое событие…. DOSKI – это истории, вкусы и эмоции, которые мы создаем для вас ежедневно."
        heroClassName="hero-bar-lunch"
        textShadow={true}
        backgroundImage={lunchBackgroundImage}
      />
      <MenuElem view="kitchen" />
      <section data-section="events">
        <CustomSwiper showDetails={true} />
        <button class="primary tg-upper"
          onClick={(e) => {
            navigate("/reservation");
          }}
        >
          Забронировать стол
        </button>
      </section>
      <AboutCards exclude={2} />
    </>
  );
}
