import { createContext } from "react";

export const PreorderContext = createContext({
    id: undefined
})

export const ReservationContext = createContext({
    rangeWeekdays: [],
    rangeWeekends: [],
})
