import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import useResizeWindows from "../../utils/hooks/useResizeWindow";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import "./style.scss";
import "./cardCarousel.scss";

// const CardCarousel = ({ loadedBenefits, itemsPerPage }) => {
//     const [startIndex, setStartIndex] = useState(0);
//     const [animationClass, setAnimationClass] = useState("animated");

//     const handleClickPrev = () => {
//       setAnimationClass("");
//       if (startIndex > 0) {
//         setStartIndex(startIndex - 1);
//       }
//       setTimeout(() => setAnimationClass("animated"), 50);
//     }

//     const handleClickNext = () => {
//       setAnimationClass("");
//       if (startIndex + 1 < loadedBenefits.length) {
//         setStartIndex(startIndex + 1);
//       }
//       setTimeout(() => setAnimationClass("animated"), 50);
//     }

//     const renderCards = () => {
//       const endIndex = startIndex + itemsPerPage;

//       return (
//         <>
//           {loadedBenefits.slice(startIndex, endIndex).map((benefit, index) => (
//             <div key={index} className={`card ${animationClass}`} style={{ flex: "1", maxWidth: "30%", margin: "0 1rem", display: "flex", flexDirection: "column" }}>
//           <div style={{ flex: "1", overflow: "hidden", position: "relative" }}>
//             <img src={benefit.img} style={{ width: "100%", height: "auto", position: "absolute", top: "33.5%", transform: "translateY(-45%)" }} />
//           </div>
//           <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
//             <div className="text-container tg-authentic large tg-upper">
//               <img className="icon" src={benefit.icon} />
//               <p>{benefit.text}</p>
//             </div>
//           </div>
//         </div>
//         ))}
//       </>
//     );
//   };

//   const prevButtonDisabled = startIndex === 0;
//   const nextButtonDisabled = startIndex + itemsPerPage >= loadedBenefits.length;

//   return (
//     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
//       <button
//         onClick={handleClickPrev}
//         disabled={prevButtonDisabled}
//         style={{
//           borderRadius: '50%',
//           opacity: prevButtonDisabled ? '0.5' : '1',
//         }}
//       >
//         {'<'}
//       </button>
//       <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '2rem' }}>
//         {renderCards()}
//       </div>
//       <button
//         onClick={handleClickNext}
//         disabled={nextButtonDisabled}
//         style={{
//           borderRadius: '50%',
//           opacity: nextButtonDisabled ? '0.5' : '1',
//         }}
//       >
//         {'>'}
//       </button>
//     </div>
//   );
// };

const CardCarousel = ({ loadedBenefits, openInquiry }) => {
  const width = useResizeWindows();
  const getSpaceBetween = (windowWidth) => {
    if (windowWidth > 2500) return 15;
    if (1991 <= windowWidth && windowWidth <= 2500) return 10;
    if (1439 < windowWidth && windowWidth <= 1999) return -4;

    if (1023 < windowWidth && windowWidth <= 1439) return -2;

    if (769 < windowWidth && windowWidth <= 1023) return 1;
    if (374 < windowWidth && windowWidth <= 767) return 0;
    if (windowWidth <= 374) return 16;
  };
  const getSlidesPerView = (windowWidth) => {
    //1024
    if (1023 < windowWidth) return 3;
    if (768 < windowWidth && windowWidth <= 1023) return 3;
    if (640 < windowWidth && windowWidth <= 768) return 2.5;
    if (449 < windowWidth && windowWidth <= 640) return 2;

    if (windowWidth > 375 && windowWidth <= 449) return 1.5;
    if (windowWidth <= 375) return 1.27;
  };
  const [activeIndex, setActiveIndex] = useState(0);

  const renderCards = () => {
    return (
      <>
        {loadedBenefits.map((benefit, index) => (
          <SwiperSlide>
            <div className="custom-slide">
              <div
                key={index}
                className={`card`}
                style={{
                  margin: "0 1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flexGrow: "1",
                    overflow: "hidden",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                >{benefit.img}</div>
                <div
                  style={{
                    flexShrink: "1",
                    // height: '50%',
                    height: '45%',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div className="text-container tg-authentic large tg-upper">
                    {benefit.icon}
                    <p>{benefit.text}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="custom-swiper" style={{
        maxWidth: '90vw',
        marginInline: 'auto',
      }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={getSpaceBetween(width)}
          slidesPerView={getSlidesPerView(width)}
          navigation={
            width >= 1024
              ? {
                  nextEl: ".swiper-button-next3",
                  prevEl: ".swiper-button-prev3",
                }
              : false
          }
          pagination={
            width < 1024
              ? { clickable: true, el: ".swiper-pagination3", type: "bullets" }
              : false
          }
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
          }}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {loadedBenefits &&
            loadedBenefits.length &&
            loadedBenefits.length > 0 &&
            renderCards()}
        </Swiper>

        <div className="swiper-button-prev3"></div>
        <div className="swiper-button-next3"></div>

        <div className="swiper-pagination3"></div>

      </div>
      <div className="counter-button">
        <div className="counter">
          <p className="tg-authentic large">
            <span>{
              width > 768 ?
              activeIndex + 3
              : width < 450 ? activeIndex + 1
              : activeIndex + 2
            }</span>
            <span>\</span>
            <span>{loadedBenefits.length}</span>
          </p>
        </div>
        <button className="secondary tg-upper" onClick={openInquiry}>Оставить заявку</button>
      </div>
    </>
  );
};

export default CardCarousel;
