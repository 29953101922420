import React from "react";
import { useNavigate } from "react-router-dom"

import AboutCards from "../../components/layout/About";
import CustomSwiper from "../../components/layout/CustomSwiper/CustomSwiper";
import Banner from "../../components/Banner/Banner";

import danceBackgroundImage from '../../assets/img/bar/dance.jpeg';

import "./style.scss";


export default function Dances() {
  const navigate = useNavigate()
  return (
    <>
      <Banner
        mainTitle="Танцы"
        buttons={[["Забронировать стол", "/reservation"]]}
        firstParagraph="Большой танцпол и лучшие артисты города. Отличная возможность отдохнуть в любой день недели."
        secondParagraph="Наша команда АРТ состава создает идеальную обстановку для вашего отдыха. Самые шумные и атмосферные вечеринки в городе. Приглашенные артисты. Много звука. Много света. Большой танцпол. Обстановка веселья, отрыва и счастья. Если вы любите отдыхать под современную музыку и хотите окунуться в ночную жизнь города, ждем вас ежедневно с 21:00."
        backgroundImage = {danceBackgroundImage}
      />
      <section data-section="events">
        <CustomSwiper showDetails={true} />
        <button class="primary tg-upper w-100 mobile-hidden"
          onClick={(e) => {
            navigate("/reservation");
          }}
        >
          Забронировать стол
        </button>
      </section>
      <AboutCards exclude={1} />
    </>
  );
}
