// export const addressesArray = [
//   {
//     id: 1,
//     city: 'Воронеж',
//     address: 'ул. Плехановская, 9',
//     phone: '+7 473 229-55-92',
//   },
//   {
//     id: 2,
//     city: 'Казань',
//     address: 'ул. Пушкина, 17',
//     phone: '+7 473 229-55-92',
//   },
//   {
//     id: 3,
//     city: 'Кемерово',
//     address: 'ул. Ноградская, 1',
//     phone: '+7 473 229-55-92',
//   },    
//   {
//     id: 4,
//     city: 'Новокузнецк',
//     address: 'пр. Металлургов, 16',
//     phone: '+7 473 229-55-92',
//   },    
//   {
//     id: 5,
//     city: 'Новосибирск',
//     address: 'ул. Вокзальная магистраль, 16 пр-кт Карла Маркса, 37/2',
//     phone: '+7 473 229-55-92',
//   },

//   {
//     id: 6,
//     city: 'Омск',
//     address: 'ул. Карла Либкнехта, 11',
//     phone: '+7 473 229-55-92',
//   },    
//   {
//     id: 7,
//     city: 'Пермь',
//     address: 'ул. Ленина, 60',
//     phone: '+7 473 229-55-92',
//   },    
//   {
//     id: 8,
//     city: 'Самара',
//     address: 'ул. Московское шоссе 4а стр. 1',
//     phone: '+7 473 229-55-92',
//   },    
//   {
//     id: 9,
//     city: 'Санкт-Петербург',
//     address: 'ул. Звенигородская 1,к 2',
//     phone: '+7 473 229-55-92',
//   },    
//   {
//     id: 10,
//     city: 'Челябинск',
//     address: 'ул. Елькина, 5',
//     phone: '+7 473 229-55-92',
//   },

// ];

export const addressesArray = [
  {
    id: 1,
    city: 'Воронеж',
    address: 'ул. Плехановская, 9',
    phone: '+7 473 229-55-92',
  },
  {
    id: 6,
    city: 'Омск',
    address: 'ул. Карла Либкнехта, 11',
    phone: '+7 473 229-55-92',
  }, 

  {
    id: 2,
    city: 'Казань',
    address: 'ул. Пушкина, 17',
    phone: '+7 473 229-55-92',
  },
  {
    id: 7,
    city: 'Пермь',
    address: 'ул. Ленина, 60',
    phone: '+7 473 229-55-92',
  },    
  
  {
    id: 3,
    city: 'Кемерово',
    address: 'ул. Ноградская, 1',
    phone: '+7 473 229-55-92',
  },    
  {
    id: 8,
    city: 'Самара',
    address: 'ул. Московское шоссе 4а стр. 1',
    phone: '+7 473 229-55-92',
  },    

  {
    id: 4,
    city: 'Новокузнецк',
    address: 'пр. Металлургов, 16',
    phone: '+7 473 229-55-92',
  },    
  {
    id: 9,
    city: 'Санкт-Петербург',
    address: 'ул. Звенигородская 1,к 2',
    phone: '+7 473 229-55-92',
  },    

  {
    id: 5,
    city: 'Новосибирск',
    address: 'ул. Вокзальная магистраль, 16 пр-кт Карла Маркса, 37/2',
    phone: '+7 473 229-55-92',
  },
  {
    id: 10,
    city: 'Челябинск',
    address: 'ул. Елькина, 5',
    phone: '+7 473 229-55-92',
  },

]

export const itemRules = [
  {required: true, message: 'Поле не заполнено'},
]
