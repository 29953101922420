import React from 'react'
import { Link } from 'react-router-dom'

import { useScrollOnRender } from '../../utils/hooks/scroll'

import BarImg from "../../assets/img/bar/bar.png"
import DanceImg from "../../assets/img/bar/dance.jpeg"
import LunchImg from "../../assets/img/bar/lunch.jpeg"
import KaraokeImg from "../../assets/img/bar/karaoke.jpeg"

import localStyle from './About.module.scss'


const cards = [
    <div key="bar" className={localStyle["card"]}>
        <img src={BarImg} alt="наливаютъ"/>
        <div className={localStyle["chips"]}>Вечером</div>
        <p className={localStyle["tg-authentic"] + ' ' + localStyle["tg-upper"]}>Бар</p>
        <p>Вас ждет огромное количество разнообразных коктейлей, напитков и блюд по самым доступным ценам в городе</p>
        <Link to="/bar"><p className={localStyle["arrow"]}>Подробнее</p></Link>
    </div>,
    <div key="dance" className={localStyle["card"]}>
        <img src={DanceImg} alt="танцующие люди" />
        <div className={localStyle["chips"]}>Ночью</div>
        <p className={localStyle["tg-authentic"] + ' ' + localStyle["tg-upper"]}>Танцы</p>
        <p>Вечеринки каждый день. Лучшие DJ, ведущие и артисты города</p>
        <Link to="/dance"><p className={localStyle["arrow"]}>Подробнее</p></Link>
    </div>,
    <div key="lunch" className={localStyle["card"]}>
        <img src={LunchImg} alt="трапеза" />
        <div className={localStyle["chips"]}>Всегда</div>
        <p className={localStyle["tg-authentic"] + ' ' + localStyle["tg-upper"]}>Кухня</p>
        <p>Разнообразное меню кухни по доступным ценам 7 дней в неделю</p>
        <Link to="/kitchen"><p className={localStyle["arrow"]}>Подробнее</p></Link>
    </div>,
    <div key="karaoke" className={localStyle["card"]}>
        <img src={KaraokeImg} alt="поющая барышня" />
        <div className={localStyle["chips"]}>Всегда</div>
        <p className={localStyle["tg-authentic"] + ' ' + localStyle["tg-upper"]}>Караоке</p>
        <p>Стильные и современные залы с широким выбором песен на любой вкус и жанр</p>
        <Link to="/karaoke"><p className={localStyle["arrow"]}>Подробнее</p></Link>
    </div>,
]

/**
 * exclude: 0 - bar, 1 - dance, 2 - lunch, 3 - karaoke (как индексы в массиве выше)
 */
export default function AboutCards({
    exclude
}) {
    useScrollOnRender(true)
    return (
        <section className={localStyle["about"]}>
            <div className={localStyle["cards"] + ' ' + localStyle["inarow"]}>
                {
                    typeof exclude === 'number' ?
                    cards.slice(0, exclude).concat(cards.slice(exclude + 1))
                    : cards
                }
            </div>
        </section>
    )
}
