import React from "react";
import { useNavigate } from "react-router-dom";

import { MenuElem } from "../../components/shared/Menu/Menu";
import AboutCards from "../../components/layout/About";
import CustomSwiper from "../../components/layout/CustomSwiper/CustomSwiper";
import Banner from "../../components/Banner/Banner";

import barBackgroundImage from "../../assets/img/bar/bar.png";

import "./style.scss";


export default function Bar() {
  const navigate = useNavigate();
  return (
    <>
      <Banner
        mainTitle="Бар"
        buttons={[["Забронировать стол", "/reservation"]]}
        firstParagraph="В нашем меню широкий выбор алкогольных и безалкогольных напитков по самым доступным ценам в городе"
        secondParagraph="У нас вы можете насладиться коктейлями различных стилей и вкусов, приготовленными нашими опытными барменами. Мы предлагаем классические коктейли и авторские рецепты нашим гостям. Большой выбор пива различных сортов и стран происхождения. Наши профессиональные бармены всегда готовы посоветовать и помочь вам выбрать самый подходящий вариант с учетом вашего настроения и предпочтений."
        backgroundImage={barBackgroundImage}
      />
      <MenuElem view="bar" />
      <section data-section="events">
        <CustomSwiper showDetails={true} />
        <button
          className="primary tg-upper"
          onClick={(e) => {
            navigate("/reservation");
          }}
        >
          Забронировать стол
        </button>
      </section>
      <AboutCards exclude={0} />
    </>
  );
}
