/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Checkbox } from 'antd';

import { usePersonalDataModal, PersonalDataModal } from '../PersonalData';

import './styles.scss';

export const CustomCheckbox = ({
  openVisitingRules,
}) => {
  const {
    personalDataModal,
    close, open,
  } = usePersonalDataModal()
  return (
    <>
      <Form.Item>
        <Checkbox required>С <a href='#' onClick={e => {
          e.preventDefault()
          open()
        }}>согласием на обработку персональных данных</a> и <a href='#' onClick={e => {
          e.preventDefault()
          openVisitingRules(true)
        }}>правилами посещения</a> ознакомлен</Checkbox>
      </Form.Item>
      <PersonalDataModal
        {...personalDataModal} close={close}
      />
    </>
  )
}

export default CustomCheckbox;
