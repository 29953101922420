import { useEffect } from "react";

import { API_URL } from "../App";

const API_ENDPOINT = '/settings/title'

/**
 * fetches HTML title from server and sets it up
*/
export default function useTitle() {
    useEffect(() => {
        fetch(API_URL + API_ENDPOINT).then(
            async response => {
                const t = await response.text()
                if (response.status < 300) return t
                else return Promise.reject(t)
            }
        ).then(
            content => {
                if (content) document.title = content
            }
        ).catch(
            error => {
                console.warn("was unable to set a document title", error)
            }
        )
    }, [])
}
