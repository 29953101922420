import { Button, Modal } from "antd";
import style from './index.module.scss'
import { useCallback, useContext, useState } from "react";
import { RestaurantContext } from "../../providers/restaurant";
import { haversine } from '../../utils/geo'
import { SAVED_REST_ID_KEY } from "../../App";


const CLASSNAME = "geolocation-request"


export default function C({
    onCancel,
    open=false,
}) {
    const {
        places, current, setCurrent,
    } = useContext(RestaurantContext)
        , [requesting, setRequesting] = useState(false)

    const doGeolocation = useCallback((e) => {
        let clientClosestRest = -1, clientClosestDistance
        if (!navigator.geolocation) {
            console.warn("!navigator.geolocation")
        } else {
            navigator.geolocation.getCurrentPosition(
                ({timestamp, coords}) => {
                    console.info("geolocation request at", timestamp)
                    places?.forEach(
                        (p, idx) => {
                        const [lat_, lng_] = p.coordinates.split(',')
                        const pLat = Number(lat_), pLng = Number(lng_)
                        const d = haversine(
                            coords.latitude, coords.longitude,
                            pLat, pLng
                        )
                        if (idx === 0) {
                            clientClosestDistance = d
                            clientClosestRest = idx
                        }
                        if (d < clientClosestDistance) {
                            clientClosestDistance = d
                            clientClosestRest = idx
                        }
                        }
                    )
                    console.info("clientClosestDistance", clientClosestDistance)
                    console.info("clientClosestRest", clientClosestRest)
                    setCurrent(clientClosestRest)
                    localStorage.setItem(SAVED_REST_ID_KEY, places[clientClosestRest].id)
                    onCancel(e)
                },
                error => {
                    console.error("ошибка при определении геолокации", error)
                    setRequesting(false)
                    onCancel(e)
                },
                {
                    enableHighAccuracy: true,
                },
            )
            setRequesting(true)
        }
    }, [places, onCancel, setCurrent])

    return <Modal
        onCancel={onCancel}
        open={open}
        footer={null}
        closeIcon={null}
        data-modal={CLASSNAME}
        className={style[CLASSNAME] || ''}
    >
        {
            requesting ? <p>Браузер получает данные геолокации..</p>
            : <>
            <h3>
                Мы попытались определить ближайший к Вам ресторан, наше предположение:<br/>
                {places?.[current]?.city}, {places?.[current]?.address}<br/>
                <span>Оно может быть довольно неточным в силу многих причин (например, просмотр сайта не из РФ или из-под VPN)</span>
            </h3>
            <h4>Позволите выбрать ближайший ресторан на основе данных геопозиции из браузера?</h4>
            <h5>Это может дать более точный результат.</h5>
            <section className="buttons">
                <Button onClick={doGeolocation}>Да, давайте попробуем</Button>
                <Button onClick={onCancel}>Нет, я выберу ресторан в&nbsp;меню сайта</Button>
            </section>
            </>
        }
    </Modal>
}
