import React from "react";
import { useNavigate } from "react-router-dom";

import localStyle from "./Banner.module.scss";


const Banner = ({
  mainTitle,
  buttons,
  firstParagraph,
  secondParagraph,
  backgroundImage,
  textShadow,
}) => {
  const navigate = useNavigate();
  const handleButtonClick = (path) => {
    if (path) {
        navigate(path)
    }
  };

  return (
    <>
      <div
        className={localStyle["banner"] + (textShadow ? " lunch-title-shadow" : "")}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <h1 className="meta"><p className={localStyle["main-title"]}>{mainTitle}</p></h1>
        {buttons &&
          buttons.map(([buttonText, path], index) => (
            <button
              key={index}
              className={localStyle["button-primary"] + ' ' + localStyle["banner-button"]}
              onClick={() => handleButtonClick(path)}
            >
              {buttonText}
            </button>
          ))}
      </div>

      <div className={localStyle["banner-description"]}>
        <p className={localStyle["firstParagraph"]}>{firstParagraph}</p>
        <p className={localStyle["secondParagraph"]}>{secondParagraph}</p>
      </div>
    </>
  );
};

export default Banner;
