import React from 'react';

import './style.scss';


const Ticker = ({
  name, style
}) => {

  const title = {
    news: "<span>✦</span> НОВОСТИ И АКЦИИ <span>✦</span> НОВОСТИ И АКЦИИ <span>✦</span> НОВОСТИ И АКЦИИ <span>✦</span> НОВОСТИ И АКЦИИ",
    advantage: "<span>✦</span> НАШИ ПРЕИМУЩЕСТВА <span>✦</span> НАШИ ПРЕИМУЩЕСТВА <span>✦</span> НАШИ ПРЕИМУЩЕСТВА <span>✦</span> НАШИ ПРЕИМУЩЕСТВА"
  }
  return (
  <div className="marquee-container">
    <p style={style} className="marquee" dangerouslySetInnerHTML={{__html: title[name]}} />
  </div>
  )
}

export default Ticker;
