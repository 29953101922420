import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Collapse } from 'antd';

import { RestaurantContext } from '../../../providers/restaurant';

import { useMap } from '../../../hooks/useMap';
import useResizeWindows from '../../../utils/hooks/useResizeWindow';

import { ReactComponent as LocationSvg } from '../../../assets/icons/location.svg';
import { ReactComponent as ArrowDownSvg } from '../../../assets/icons/arrowDown.svg';

import localStyle from  './style.module.scss'


function DynamicLoc({
  place
}) {
    const bounds = useMemo(() => place?.map_bounds || null, [place])
      , [x, y] = useMemo(() => place?.coordinates?.split(',').map(
        coord => {
          return Number(coord.trim())
        }
      ) || [0, 0], [place])
  useMap({x, y, bounds})

  return <div id="map" className={localStyle['map-mobile']}></div>
}

const Locations = () => {
  let {
    places, current,
  } = useContext(RestaurantContext)
  const width = useResizeWindows();

  //Сделать кнопку и по нажатию раскрывать весь список
  const [isLocked, setIsLoked] = useState(true);
  const [activeCollapseId, setActiveCollapseId] = useState(`${places?.[current]?.id}`);

  const collapseItems = places?.map((fullAddress) => {
    // console.log(`key: ${fullAddress?.id}, `, typeof fullAddress?.id, fullAddress?.id,)
    return({
      key: `${fullAddress?.id}`,
      label: <div className={localStyle['mobile-location-wrapper']}>
          <div className={localStyle['location-wrapper']}>
            <div className={localStyle['location-icon']}><LocationSvg /></div>
            <div className={localStyle['city']}>{fullAddress.city}</div>
          </div>
          <div className={localStyle['arrow-down-svg']}><ArrowDownSvg/></div>
        </div>,
      children: <>
        <div className={localStyle['full-address']}>
          <div className={localStyle['address']}>{fullAddress.address}</div>
          <a href={`tel:${fullAddress.phone}`} style={{color: 'var(--color-button-primary)'}}><div className={localStyle['phone']}>{fullAddress.phone}</div></a>
        </div>
        <DynamicLoc place={fullAddress}/>
      </>,
    })
  });

  useEffect(() => {
    try {
      setActiveCollapseId(`${places?.[current]?.id}`)
    } catch {
      
    }
  }, [places, current])

  const collapseItemsM = useMemo(() => {
    return (
      <div className={`${localStyle['child']} ${localStyle['mobile-collapse-wrapper']}`}
        style={{
          minHeight: 'auto',
          height: 'auto',
          //   :places?.reduce(
          //   (prev, curr, idx) => {
          //     let toAdd = 0
          //     // eslint-disable-next-line eqeqeq
          //     if (curr?.id == activeCollapseId) {
          //       toAdd = 626
          //       collapseHeightIndexStop.current = idx + 2
          //     } else if (
          //       idx < collapseHeightIndexStop.current
          //       || collapseHeightIndexStop.current === -1
          //     ) {
          //       toAdd = 58
          //     }
          //     return prev + toAdd
          //   },
          //   0,
          // )
        }}
      >
          <Collapse
            items={collapseItems} activeKey={activeCollapseId}
            onChange={
              (e) => {
                console.log('onchange', e)
                console.log('activeCollapseId: ', activeCollapseId)
                setActiveCollapseId(e[1])
              }
            }
          />
        </div>
    )
  }, [collapseItems, activeCollapseId])

  return (
    <div className={localStyle['locations']}>
      {width <= 768 ?
      <>
        <div className={
          `${localStyle['mobile-collapse-block'] || ''} ${localStyle['isLocked'] + ' ' + ((localStyle['parent'] || ''))} ${activeCollapseId !== '' && activeCollapseId !== undefined && isLocked ? (localStyle['heightWithImage'] || '') : (localStyle['heightWithoutImage'] || '')}`
        }>
          {collapseItemsM}
          {/* <div className={`${localStyle['collapse-button']} ${localStyle['whiteButton']} ${!isLocked ? '' : localStyle['gradient-hide']}`}>
            <Button onClick={() => setIsLoked(!isLocked)}>{!isLocked ? 'Развернуть' : 'Cвернуть'}</Button>
          </div> */}
        </div>
      </> 
        : places?.map((fullAddress, idx) => (
          <div className={localStyle['location-wrapper']}>
            <div className={localStyle['location-icon']}><LocationSvg /></div>
            <div className={localStyle['full-address']}>
              <div className={`${localStyle['city']} ${current === idx ? localStyle['city-active'] : ''}`}>{fullAddress.city}</div>
              <div className={localStyle['address']}>{fullAddress.address}</div>
              <div className={localStyle['phone']}>{fullAddress.phone}</div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Locations;
