export function getWeekDay(date) {
  date = new Date(date);
  let days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

  return days[date.getDay()];
}

// let date = new Date(2014, 0, 3); // 3 января 2014 года
// alert( getWeekDay(date) ); // ПТ

export function removeParentheses(string) {
  return string?.replaceAll(/[()]/g, '')
}

export function isWeekEnd(date) {
  const nums = new Set([4, 5])
  let dow

  if (date) dow = date.getUTCDay()
  else dow = new Date().getUTCDay()

  return nums.has(dow)
}

export function extractHour(timeStr) {
  if (!timeStr) return undefined
  return Number(timeStr.split(':')[0])
}

export function extractMinute(timeStr) {
  if (!timeStr) return undefined
  return Number(timeStr.split(':')[1])
}
