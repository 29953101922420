import { useContext, useEffect, useState } from "react"
import { Modal } from "antd"
import { API_URL } from "../../../App"

import { RestaurantContext } from "../../../providers/restaurant"

const reset = {
    showing: false,
}

export function usePersonalDataModal() {
    const [personalDataModal, setPersonalDataModal] = useState(reset)
    const close = () => {
        setPersonalDataModal(reset)
    }
    const hide = () => {
        setPersonalDataModal({...personalDataModal, showing: false})
    }
    const open = () => {
        setPersonalDataModal({showing: true})
    }

    return {
        personalDataModal,
        close, open,
    }
}

export function PersonalDataModal({
    ...props
}) {
    const {
        places, current,
    } = useContext(RestaurantContext)
    const [t, sT] = useState("Здесь будут условия (в дизайне я модала на тему обработки персональных данных не нашёл)")
    useEffect(() => {
        if (places?.[current]?.id) {
            fetch(API_URL + `/settings/personal_data?rest_id=${places?.[current]?.id}`).then(
                res => {
                    if (res.status < 400) return res.text()
                    else return Promise.reject(res.text())
                }
            ).then(
                content => {
                    sT(content)
                }
            ).catch(
                e => {
                    console.error(e)
                }
            )
        }
    }, [places, current])

    return (
        <Modal
            open={props.showing}
            onCancel={props.close}
            footer={null}
            style={{
                maxWidth: '80%',
                minWidth: 360,
            }}
        ><div dangerouslySetInnerHTML={{__html: t}} /></Modal>
    )
}
