import { Button } from 'antd';
import './styles.scss';


function Counter({ value, onChange }) {
  return(<>
    <div className='counter'>
      <div className={`reduce whiteButton ${value <= 1 ? 'disabled' : ''}`}>
        <div className={`${value <= 1 ? 'disabled-area-show': 'disabled-area-hide' }`}></div>
        <Button
          onClick={() => onChange( value - 1)}
        >
        -
        </Button>
      </div>
      <span className='number'>{value}</span>
      <div className='increase whiteButton'>
        <Button
          onClick={() => onChange( value + 1)}
        >
        +
        </Button>
      </div>
    </div>
  </>)
}

export default Counter;