import React, { useContext, useEffect, useMemo, useState, useRef, } from 'react';
import {
  Form, Select, Input, Checkbox, Button, Modal,
} from 'antd';
import { MaskedInput } from "antd-mask-input"

import { ReactComponent as ArrowDownSvg } from '../../assets/icons/greenArrowDown.svg';
import WorkersImg from '../../assets/img/workers.png'

import VideoPlacholder from '../../assets/img/jobs/video-cover.png'

import { RestaurantContext } from '../../providers/restaurant'

import { removeParentheses } from '../../utils/helpers/functions';
import { makeFormData } from '../../utils/helpers/request';
import { useScrollOnRender } from '../../utils/hooks/scroll';
import useResizeWindows from '../../utils/hooks/useResizeWindow';
import { usePersonalDataModal, PersonalDataModal } from '../../components/shared/PersonalData';

import { API_HOST, API_URL } from '../../App'
import { itemRules } from '../../utils/helpers/constants'

import './styles.scss'
import { getAddressOptions } from '../../utils/restaurant';


const { Item } = Form;
const { TextArea } = Input;


const countryPrefix = "+7"
    , formConfig = {
      "last_name": "Фамилия",
      "first_name": "Имя",
      "middle_name": "Отчество",
      "phone": "Телефон",
      "rest_id": "Ресторан",
      "position": "Должность",
      "about": "Немного о себе",

      "personalData": "Согласие о данных",
      "isAdult": "Возрастной ценз",
    }

const Job = () => {
  useScrollOnRender(true)
  const {places, current} = useContext(RestaurantContext)
  const width = useResizeWindows();

  const [applicationState, setApplicationState] = useState({
    showingModal: false,
    inputState: {},
  })

  function applicationOk(v) {
    setApplicationState({showingModal: true, result: v})
  }
  function applicationError(e) {
    setApplicationState({showingModal: true, error: e})
  }

  function submitApplication(values) {
    const body = makeFormData(
      values,
      "last_name", "first_name", "middle_name",
      "phone", "rest_id", "position", "about",
    )
    if (body instanceof Map) {
      applicationError(body)
      return
    }
    body.set("phone", countryPrefix + body.get("phone"))
    body.set("city", places?.[current]?.city)
    setApplicationState(prev => ({...prev, pending: true, showingModal: true}))
    fetch(API_HOST + '/career', {
      method: 'POST',
      body,
    }).then(
      response => response.json()
    ).then(
      content => {
        applicationOk(content)
      }
    ).catch(
      e => {
        applicationError(e)
      }
    ).finally(
      () => {
        setApplicationState(prev => ({...prev, pending: false}))
      }
    )
  }

  // стоит выделить компонент или блок кода, но мне лень
  const modalBody = useMemo(() => {
    let ret
    if (applicationState.pending) {
      ret = <p>Ожидаем сервер...</p>
    } else if (applicationState.error || applicationState.result?.status === 'error') {
      ret = <div className='bad'><p>{`${applicationState.error}`}</p></div>
    } else if (applicationState.result?.status === 'success') {
      ret = <div className='good'>
        <div className='header'></div>
        <div className='body'><span style={{
          whiteSpace: width > 1024 ? 'nowrap' : 'initial'
        }}>Ваша заявка принята.</span><br/>Мы с&nbsp;Вами свяжемся в&nbsp;ближайшее время!</div>
      </div>
    }
    return ret
  }, [applicationState, width])

  const {
    personalDataModal, open, close,
  } = usePersonalDataModal()

  const [video, setVideo] = useState({
    showing: false,
  })

  const workVideoRef = useRef()
      , openVideoModal = e => {
    setVideo({ showing: true, url: API_URL + '/about-work-video' })
    workVideoRef.current?.play()
  }

  const [profOpts, setProfOpts] = useState([])
  useEffect(() => {
    fetch(API_URL + '/settings/professions_list').then(
      response => {
        if (response.status < 400) return response.json()
        else return response.text()
      }
    ).then(
      content => {
        setProfOpts(content.map(o => ({value: o, label: o})))
      }
    ).catch(
      e => {
        console.error(e)
      }
    )
  }, [])

  return (
    <>
    <section class="hero career">
      <div class="text">
          <h1 className='meta'><p class="title tg-authentic tg-upper">Работа в Досках?</p></h1>
          <p class="appeal">
              Заполни анкету и стань частью команды баров «DOSKI»
          </p>
          <p class="phone tg-authentic large">{removeParentheses(places?.[current]?.phone) || "+7 846 972-60-06"}</p>
          <p class="play-video"
            onClick={openVideoModal}
          >
              <span class="play-video-button"></span>
              <span>О работе в &laquo;DOSKI&raquo;</span>
          </p>
      </div>
      <div className='workersImgWrapper'>
        <img src={WorkersImg} alt=""/>
      </div>
    </section>
    <div className='job container'>
      <div className='content'>
        <div className='job-form'>
          <Form
              onFinish={submitApplication}
            >
            {width >=769 ? <>
              <Item>
                <Item
                  name='last_name'
                  label={formConfig['last_name']}
                  rules={itemRules}
                  style={{ 
                    display: 'inline-block', 
                    width: `calc(33% - ${
                      width > 2350 ? '13px' : width > 2001 ? '8px' : (width > 1441) ? '4px' : (width > 1025) ? '4px' : '4px'
                    })`, 
                    marginBottom: '0' 
                    }}
                  >
                  <Input status='' />
                </Item>
                <Item
                  name='first_name'
                  label={formConfig['first_name']}
                  rules={itemRules}
                  style={{ 
                    display: 'inline-block', 
                    // width: 'calc(33% - 4px)', 
                    width: `calc(33% - ${width > 2350 ? '13px' : width > 2001 ? '8px' : (width > 1441) ? '4px' : (width > 1025) ? '4px' : '4px'})`, 

                    // marginInlineStart: 8 
                    marginInlineStart: width > 2350 ? 25.5 : width > 2001 ? 17 : (width > 1441) ? 8 : (width > 1025) ? 8 : 8
                  }}
                  >
                  <Input />
                </Item>
                <Item 
                  name='middle_name'
                  label={formConfig['middle_name']}
                  rules={itemRules}
                  style={{ 
                    display: 'inline-block', 
                    // width: 'calc(33% - 4px)',  
                    width: `calc(33% - ${width > 2350 ? '13px' : width > 2001 ? '8px'  : (width > 1441) ? '4px' : (width > 1025) ? '4px' : '4px'})`, 

                    // marginInlineStart: 8 
                    marginInlineStart: width > 2350 ? 25.5 : width > 2001 ? 17 : (width > 1441) ? 8 : (width > 1025) ? 8 : 8

                  }}
                >
                  <Input />
                </Item>
              </Item>

              <div
                style={{
                  display: "flex", flexDirection: "row",
                  justifyContent: "flex-start", alignItems: "center"
                }}
              >
                <Item 
                  name='phone'
                  label={formConfig['phone']}
                  data-id="phone-try"
                  rules={itemRules.concat([{
                    pattern: /\(\d{3}\)\d{3}-\d{2}-\d{2}/,
                    validateTrigger: 'blur',
                    message: "Телефон заполнен не до конца",
                  }])}
                  style={{width: `calc(${width > 1025 ? '50%' : '55.5%'} - ${width > 2001 ? '13px' : (width > 1441) ? '6px' : (width > 1025) ? '6px' : '6px'})`}} 
                  >
                    <MaskedInput
                      defaultValue=''
                      mask={'(000)000-00-00'}
                      // placeholderChar={'#'}
                      addonBefore={<Form.Item name="prefix" noStyle><div>{countryPrefix}</div></Form.Item>}
                    />
                  </Item>
                <Item 
                  name='rest_id'
                  label={formConfig['rest_id']}
                  rules={itemRules}
                  style={{
                    width: `calc(${width > 1025 ? '50%' : '44.5%'} - ${width > 2001 ? '13px' : (width > 1441) ? '8px' : (width > 1025) ? '8px' : '8px'})`,
                    marginLeft: `${width > 2001 ? '26px' : (width > 1441) ? '26px' : (width > 1025) ? '26px' : '10px'}`
                  }}
                >
                    <Select
                      // defaultValue={places?.[current]?.city || cities?.[0]?.value}
                      // onChange={handleChange}
                      options={getAddressOptions(places, places?.[current]?.id)}
                      suffixIcon={<div className='arrow-down-svg'><ArrowDownSvg /></div>}
                      placement='bottomLeft'
                      popupMatchSelectWidth={true}
                    />
                </Item>
              </div>

              <Item
                name='position'
                label={formConfig['position']}
                rules={itemRules}
                style={
                  {
                    width: `calc(${width > 1025 ? '50%' : '55.5%'} - ${width > 2001 ? '13px' : (width > 1441) ? '6px' : (width > 1025) ? '6px' : '6px'})`,
                    position: 'relative',
                  }
                } 
              >
                <Select
                  // defaultValue={positions?.[0]?.value}
                  // onChange={handleChange}
                  options={profOpts}
                  suffixIcon={<div className='arrow-down-svg'><ArrowDownSvg /></div>}
                  placement='bottomLeft'
                  popupClassName='heal-select-dropdown'
                  popupMatchSelectWidth={true}
                />
              </Item>
            </>
            : <>
              <Item>
                <Item
                  name='last_name'
                  label={formConfig['last_name']}
                  rules={itemRules}
                  style={{ 
                      display: width > 448 ? 'inline-block' : 'block', 
                      width: width > 448 ? 'calc(50% - 9px)' : '100%', 
                      marginBottom: '0' 
                    }}
                  >
                  <Input status='' />
                </Item>

                <Item
                  name='first_name'
                  label={formConfig['first_name']}
                  rules={itemRules}
                  style={{ 
                    display: width > 448 ? 'inline-block' : 'block', 
                    width: width > 448 ? 'calc(50% - 9px)' : '100%', 
                    marginInlineStart: width > 448 ? 16 : 0,
                    marginTop: width > 448 ? 0 : 21
                  }}
                  >
                  <Input />
                </Item>
              </Item>

              <Item>
                <Item 
                  name='middle_name'
                  label={formConfig['middle_name']}
                  rules={itemRules}
                  style={{ 
                    display: width > 448 ? 'inline-block' : 'block', 
                    width: width > 448 ? 'calc(50% - 9px)' : '100%', 
                  }}
                >
                  <Input />
                </Item>

                <Item
                  label={formConfig['phone']}
                  name='phone'
                  data-id="phone-try"
                  rules={itemRules.concat([{
                    pattern: /\(\d{3}\)\d{3}-\d{2}-\d{2}/,
                    validateTrigger: 'blur',
                    message: "Телефон заполнен не до конца",
                  }])}
                  style={{
                    display: width > 448 ? 'inline-block' : 'block', 
                    width: width > 448 ? 'calc(50% - 9px)' : '100%', 
                    marginInlineStart: width > 448 ? 16 : 0,
                    marginTop: width > 448 ? 0 : 21
                  }}
                >
                  <MaskedInput
                    defaultValue=''
                    mask={'(000)000-00-00'}
                    // placeholderChar={'#'}
                    addonBefore={<Form.Item name="prefix" noStyle><div>{countryPrefix}</div></Form.Item>}
                  />
                </Item>
              </Item>

              <Item>
                <Item 
                  name='rest_id'
                  label={formConfig['rest_id']}
                  rules={itemRules}
                  style={{
                      // width: `calc(50% - 9px)`
                      display: width > 448 ? 'inline-block' : 'block',
                      width: width > 448 ? 'calc(50% - 9px)' : '100%',
                      paddingInline: 0,
                    }} 
                  >
                    <Select
                      // defaultValue={places?.[current]?.city || cities?.[0]?.value}
                      // onChange={handleChange}
                      options={getAddressOptions(places, places?.[current]?.id)}
                      suffixIcon={<div className='arrow-down-svg'><ArrowDownSvg /></div>}
                      placement='bottomLeft'
                      popupMatchSelectWidth={true}
                      dropdownStyle={{
                        maxWidth: width > 448 ? 'calc(50% - 32px - 9px)' : 'calc(100% - 32px)',
                      }}
                    />
                </Item>

                <Item
                  name='position'
                  label={formConfig['position']}
                  rules={itemRules}
                  style={{
                    // width: `calc(50% - 9px)`,
                    // marginInlineStart: 16
                    display: width > 448 ? 'inline-block' : 'block',
                    width: width > 448 ? 'calc(50% - 9px)' : '100%',
                    marginInlineStart: width > 448 ? 16 : 0,
                    marginTop: width > 448 ? 0 : 21,
                    position: 'relative',
                  }}
                >
                  <Select
                    // defaultValue={positions?.[0]?.value}
                    // onChange={handleChange}
                    options={profOpts}
                    suffixIcon={<div className='arrow-down-svg'><ArrowDownSvg /></div>}
                    placement='bottomLeft'
                    popupMatchSelectWidth={true}
                    popupClassName='heal-select-dropdown'
                    dropdownStyle={{
                      maxWidth: width > 448 ? 'calc(50% - 32px - 9px)' : 'calc(100% - 32px)',
                    }}
                  />
                </Item>
              </Item>

            </>}

            <Item
              required={false}
              name='about'
              label={formConfig['about']}
              rules={itemRules}
              style={{
                display: 'inline-block',
                width: `${width > 769 ? 'calc(50% - 9px)' : '100%'}}`,
              }}
            >
              <TextArea
                  autoSize={{
                    minRows: 4,
                    maxRows: 8
                  }}
              />
            </Item>

            <Item name='personalData'>
              <Checkbox required>
                {/* onChange={(e) => !e.target.checked} */}
                С <a href="#" onClick={e => {
                  e.preventDefault()
                  open()
                }}>условиями обработки персональных данных</a> согласен
              </Checkbox>
            </Item>
            <Item name='isAdult'>
              <Checkbox required onChange={(e) => !e.target.checked}>Мне исполнилось 18 лет</Checkbox>
            </Item>

            <Button htmlType='submit'>Отправить</Button>

          </Form>
        </div>
        <div className='video'
          onClick={openVideoModal}
        >
          <div class="wrapper">
              <img src={VideoPlacholder} alt="Видео о работе в баре доски"/>
              <div class="play-video-button"></div>
          </div>
          <p>О работе в &laquo;DOSKI&raquo;</p>
        </div>
      </div>
    </div>
    <Modal
        open={applicationState.showingModal}
        title={applicationState.result ? "Присоединяйтесь к команде" : null}
        onOk={() => {
          setApplicationState({showingModal: false})
        }}
        onCancel={() => {
          setApplicationState({showingModal: false})
        }}
        footer={null}
        style={{
          maxWidth: applicationState.result?.status === 'success' ? 'min-content' : 'max-content',
        }}
      >
        <div className='request-modal'>
          {modalBody}
        </div>
    </Modal>
    <PersonalDataModal
      {...personalDataModal} close={close}
    />
    <Modal
      open={video.showing}
      onCancel={(e) => {
        setVideo({showing: false})
        workVideoRef.current.pause()
      }}
      footer={null}
      style={{
        width: 'max-content',
      }}
    >
      <div className='about-work-video-modal'>
        {/* <img src={VideoPlacholder} alt="монтируем видео" /> */}
      <video ref={workVideoRef} height="480" controls autoPlay>
        <source src={video.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </div>
    </Modal>
    </>
  )
}

export default Job;
